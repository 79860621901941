import { createSlice } from "@reduxjs/toolkit";
import { loginThunk, logoutThunk } from "./authThunk";

type StateType = {
  loginStatus: PosPazarTypes.ApiRequestStatus;
  loginErrorMessage: string | undefined;
  isUserLogout: boolean;
  forgotPasswordStatus: PosPazarTypes.ApiRequestStatus;
  forgotPasswordErrorMessage?: string;
};

export const initialState: StateType = {
  loginStatus: "idle",
  loginErrorMessage: undefined,
  isUserLogout: false,
  forgotPasswordStatus: "idle",
  forgotPasswordErrorMessage: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(loginThunk.fulfilled, (state) => {
        state.loginStatus = "succeeded";
        state.loginErrorMessage = undefined;
      })
      .addCase(loginThunk.pending, (state) => {
        state.loginStatus = "loading";
        state.loginErrorMessage = undefined;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.loginStatus = "failed";
        state.loginErrorMessage = action.error.message;
      });
    builder.addCase(logoutThunk.fulfilled, (state) => {
      state.isUserLogout = true;
    });
  },
});
export const { resetAuthState } = authSlice.actions;
export default authSlice.reducer;
