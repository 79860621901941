import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import CreditCard from "Components/CreditCard";
import CustomerInfo from "./CustomerInfo";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { changeInstallmentLoading } from "slices/installment/installmentReducer";
import { getCreditCardInstallmentOptions, new3dPayment } from "slices/thunks";
import InstallmentOptions from "./InstallmentOptions";
import { t } from "i18next";
import { toast } from "react-toastify";
import { RootState } from "slices";

const ManuelPayment = (props: PropsFromRedux) => {
  const { creditCards, paymentStatus, t } = props;
  const [activeArrowTab, setactiveArrowTab] = useState<number>(1);

  const [provider, setProvider] = useState<string>("");
  const [amount, setAmount] = useState<number>(0.0);
  const [cardNumber, setCardNumber] = useState<string>("");

  const [customerSectionIsValid, setCustomerSectionIsValid] =
    useState<boolean>(false);
  const [customerInfo, setCustomerInfo] = useState<any>({});

  const [creditCardSectionIsValid, setCreditCardSectionIsValid] =
    useState<boolean>(false);
  const [creditCard, setCreditCard] = useState<any>({});

  const [installment, setInstallement] = useState<number>(0);

  function onChangeCustomer(e: any) {
    setCustomerInfo(e);
    setProvider(e.provider);
    setAmount(Number(e.amount));
    incrementActiveArrowTab(1);
  }

  function onChangeCreditCard(e: any) {
    setCreditCard(e);
    setCardNumber(e.cardNumber);
  }

  function toggleArrowTab(tab: any) {
    if (activeArrowTab !== tab) {
      if (tab > 0 && tab <= 3) {
        setactiveArrowTab(tab);
      }
    }
  }

  function incrementActiveArrowTab(value: number) {
    const newTab = activeArrowTab + value;
    if (newTab > 0 && newTab <= 3) {
      setactiveArrowTab(newTab);
    }
  }

  const dispatch: any = useDispatch();
  useEffect(() => {
    if (amount > 0 && provider !== "" && cardNumber && cardNumber.length > 6) {
      dispatch(changeInstallmentLoading(true));
      const binNumber = cardNumber.substring(0, 6);
      dispatch(
        getCreditCardInstallmentOptions({ provider, amount, binNumber })
      );
    }
  }, [amount, provider, cardNumber, dispatch]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const payload = {
      provider: provider,
      amount: amount,
      installment: installment,
      card_holder_name: creditCard.cardHolder,
      card_no: creditCard.cardNumber,
      expire_date: creditCard.expiry,
      cvv: creditCard.cvc,
    };
    dispatch(new3dPayment(payload));
  };

  useEffect(() => {
    switch (paymentStatus) {
      case "succeeded":
        toast.success(t("You are being redirected to the payment page..."));
        break;
      case "failed":
        toast.error(t("Payment failed."));
        break;
      case "loading":
        toast.info(t("Payment request sent, please wait..."));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  return (
    <div className="page-content">
      <Container fluid>
        <Card className="card-height-100">
          <CardHeader>{t("Manual Payment")}</CardHeader>
          <CardBody>
            <Form className="form-steps" onSubmit={handleSubmit}>
              <div className="step-arrow-nav mb-4">
                <Nav
                  className="nav-pills custom-nav nav-justified"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      href="#"
                      id="steparrow-gen-info-tab"
                      className={classnames({
                        active: activeArrowTab === 1,
                        done: activeArrowTab > 1,
                      })}
                      onClick={() => {
                        toggleArrowTab(1);
                      }}
                    >
                      {t("Customer Info")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      id="steparrow-gen-info-tab"
                      className={classnames({
                        active: activeArrowTab === 2,
                        done: activeArrowTab > 2,
                      })}
                      onClick={() => {
                        if (customerSectionIsValid) {
                          toggleArrowTab(2);
                        }
                      }}
                    >
                      {t("Card Info")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      id="steparrow-gen-info-tab"
                      className={classnames({
                        active: activeArrowTab === 3,
                        done: activeArrowTab > 3,
                      })}
                      onClick={() => {
                        if (
                          customerSectionIsValid &&
                          creditCardSectionIsValid
                        ) {
                          toggleArrowTab(3);
                        }
                      }}
                    >
                      {t("Installment Options")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={activeArrowTab}>
                <TabPane id="steparrow-gen-info" tabId={1}>
                  <CustomerInfo
                    value={customerInfo}
                    onChange={(e) => onChangeCustomer(e)}
                    isValid={(e) => setCustomerSectionIsValid(e)}
                    {...props}
                  />
                </TabPane>

                <TabPane id="steparrow-description-info" tabId={2}>
                  <div>
                    <CreditCard
                      value={creditCard}
                      onChange={(e) => onChangeCreditCard(e)}
                      isValid={(e) => setCreditCardSectionIsValid(e)}
                      {...props}
                    />
                  </div>
                  <div className="d-flex align-items-start gap-3 mt-4">
                    <Button
                      type="button"
                      className="btn btn-light btn-label previestab"
                      onClick={() => {
                        toggleArrowTab(activeArrowTab - 1);
                      }}
                    >
                      <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                      {t("Customer Info")}
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-success btn-label right ms-auto nexttab nexttab"
                      onClick={() => {
                        if (creditCardSectionIsValid) {
                          toggleArrowTab(activeArrowTab + 1);
                        }
                      }}
                      disabled={!creditCardSectionIsValid}
                    >
                      <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                      {t("Installment Options")}
                    </Button>
                  </div>
                </TabPane>

                <TabPane id="pills-experience" tabId={3}>
                  <div>
                    <InstallmentOptions
                      installments={creditCards}
                      selectedInstallment={installment ?? 1}
                      onChange={(e) => setInstallement(e)}
                      {...props}
                    />
                  </div>
                  <div className="d-flex align-items-start gap-3 mt-4">
                    <Button
                      type="button"
                      className="btn btn-light btn-label previestab"
                      onClick={() => {
                        toggleArrowTab(activeArrowTab - 1);
                      }}
                    >
                      <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                      {t("Card Info")}
                    </Button>
                    <Button
                      type="submit"
                      className="btn btn-success btn-label right ms-auto"
                    >
                      <i className="ri-check-line label-icon align-middle fs-16 ms-2"></i>
                      {t("Pay")}
                    </Button>
                  </div>
                </TabPane>
              </TabContent>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  creditCards: state.CreditCard.creditCards,
  loading: state.CreditCard.loading,
  paymentStatus: state.PosProvider.paymentStatus,
});
const mapDispatchToProps = (dispatch: any) => ({
  t,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManuelPayment);
