import { createSlice } from "@reduxjs/toolkit";
import {
  getCurrenciesRates,
  getDashboardInfo,
  getUserInfo,
} from "./dashboardThunk";

type StateType = {
  userInfo: PosPazarTypes.UserInfoReducer | undefined;
  dashboardInfo: PosPazarTypes.DashboardReducer | undefined;
  dashboardStatus: PosPazarTypes.ApiRequestStatus;
  loading: boolean;
  error: string | null;
  currencyRates: PosPazarTypes.CurrencyRate[];
  currencyRatesStatus: PosPazarTypes.ApiRequestStatus;
  currencyRatesError: string | null;
};
const initialState: StateType = {
  userInfo: undefined,
  dashboardInfo: undefined,
  dashboardStatus: "idle",
  loading: false,
  error: null,
  currencyRates: [],
  currencyRatesStatus: "idle",
  currencyRatesError: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      });
    builder
      .addCase(getDashboardInfo.fulfilled, (state, action) => {
        state.dashboardStatus = "succeeded";
        state.dashboardInfo = action.payload;
      })
      .addCase(getDashboardInfo.pending, (state) => {
        state.dashboardStatus = "loading";
        state.error = null;
      })
      .addCase(getDashboardInfo.rejected, (state, action) => {
        state.dashboardStatus = "failed";
        state.error = action.error.message ?? null;
      });
    builder
      .addCase(getCurrenciesRates.fulfilled, (state, action) => {
        state.currencyRates = action.payload;
        state.currencyRatesStatus = "succeeded";
      })
      .addCase(getCurrenciesRates.pending, (state) => {
        state.currencyRatesStatus = "loading";
        state.currencyRatesError = null;
      })
      .addCase(getCurrenciesRates.rejected, (state, action) => {
        state.currencyRatesStatus = "failed";
        state.currencyRatesError = action.error.message ?? null;
      });
  },
});

export default dashboardSlice.reducer;
