import { useEffect, useMemo } from "react";
import { Badge, Button, Card, CardBody, Container, Spinner } from "reactstrap";
import TransactionModal from "./TransactionModal";
import { connect } from "react-redux";
import { getTransaction, getTransactions } from "slices/thunks";
import { Trans, withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import CustomTable from "Components/CustomTable";
import { toast } from "react-toastify";

const Transaction = (props: {
  transactions: Array<PosPazarTypes.TransactionListItem>;
  pageNumber: number;
  status: PosPazarTypes.ApiRequestStatus;
  error: string | null;
  fetchTransactions: (pageNumber?: number) => void;
  fetchTransaction: (uniq: string) => void;
  t: TFunction;
}) => {
  const {
    transactions,
    pageNumber,
    error,
    status,
    fetchTransactions,
    fetchTransaction,
    t,
  } = props;

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const columns = useMemo<Array<PosPazarTypes.CustomTableColumn>>(
    () => [
      {
        header: t("Amount"),
        accessor: "amount",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          return row.amount + " ₺";
        },
      },
      {
        header: t("Total Amount"),
        accessor: "total_amount",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          return row.total_amount + " ₺";
        },
      },
      {
        header: t("Installment"),
        accessor: "installment",
      },
      {
        header: t("Status"),
        accessor: "status",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          const status = row.status;
          const color =
            status === "approved"
              ? "success"
              : status === "pending"
              ? "warning"
              : "danger";
          return (
            <Badge color={color} pill={true}>
              <Trans i18nKey={status} />
            </Badge>
          );
        },
      },
      {
        header: t("Created At"),
        accessor: "created_at",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          const date = new Date(row.created_at);
          return date.toLocaleDateString("tr-TR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
        },
      },
      {
        header: t("Updated At"),
        accessor: "updated_at",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          const date = new Date(row.updated_at);
          return date.toLocaleDateString("tr-TR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
        },
      },
      {
        header: "",
        accessor: "actions",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          return (
            <div className="d-flex justify-content-center gap-3">
              <Button
                className="btn btn-primary"
                onClick={() => fetchTransaction(row.uniq)}
                disabled={status === "loading"}
              >
                {status === "loading" ? (
                  <Spinner size="sm" />
                ) : (
                  <Trans i18nKey="Details" />
                )}
              </Button>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (error) {
      toast.error(error, { autoClose: 5000 });
    }
  }, [error]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <CustomTable
                columns={columns}
                data={transactions}
                onPageChange={(pn) => {
                  if (pn !== pageNumber) {
                    fetchTransactions(pn);
                  }
                }}
                {...props}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
      <TransactionModal />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  transactions: state.Transaction.transactions,
  totalData: state.Transaction.count,
  pageNumber: state.Transaction.pageNumber,
  status: state.Transaction.status,
  error: state.Transaction.error,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchTransactions: (pageNumber?: number) =>
      dispatch(getTransactions(pageNumber)),
    fetchTransaction: (uniq: string) => dispatch(getTransaction(uniq)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Transaction));
