import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCoreCities,
  getCoreCountries,
  getCoreProviders,
  getCoreTownships,
  postMerchantApplication,
} from "helpers/api/inner_urls";

export const getAllCountries = createAsyncThunk(
  "core/getAllCountries",
  async () => {
    const { data } = await getCoreCountries();
    return data;
  }
);

export const getAllCities = createAsyncThunk("core/getAllCities", async () => {
  const { data } = await getCoreCities();
  return data;
});

export const getAllTownships = createAsyncThunk(
  "core/getAllTownships",
  async (city_id: number) => {
    const { data } = await getCoreTownships(city_id);
    return data;
  }
);

export const getAllCoreProviders = createAsyncThunk(
  "core/getAllCoreProviders",
  async () => {
    const { data } = await getCoreProviders();
    return data;
  }
);

export const sendMerchantApplication = createAsyncThunk(
  "core/sendMerchantApplication",
  async (body: PosPazarTypes.MerchantApplicationBody) => {
    await postMerchantApplication(body);
  }
);
