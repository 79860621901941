export class AppEnv {
  public static get Env(): "dev" | "test" | "prod" {
    return (
      (process.env.REACT_APP_ENV as "dev" | "test" | "prod" | undefined) ||
      "dev"
    );
  }

  public static get WithCredentials(): boolean {
    return process.env.REACT_APP_WITH_CREDENTIALS === "true";
  }
  public static get ApiUrl(): string {
    return process.env.REACT_APP_API_URL || "";
  }

  public static get UiUrl(): string {
    return process.env.REACT_APP_UI_URL || "";
  }

  public static get PaymentUrl(): string {
    return process.env.REACT_APP_PAYMENT_URL || "";
  }

  public static get PublicUrl(): string {
    return process.env.REACT_APP_PUBLIC_URL || "";
  }

  public static get TetraxUrl(): string {
    return process.env.REACT_APP_TETRAX_URL || "";
  }

  public static get MailUrl(): string {
    return "https://www.pospazar.com";
  }
}
