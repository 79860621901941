import { createSlice } from "@reduxjs/toolkit";
import { patchActiveDealer, fetchDealers } from "./dealerThunk";
import { toast } from "react-toastify";

type StateType = {
  dealerList: PosPazarTypes.DealerList[];
  listStatus: PosPazarTypes.ApiRequestStatus;
  dealer: PosPazarTypes.Dealer | undefined;
  dealerStatus: PosPazarTypes.ApiRequestStatus;
  error: string | null;
};

const initialState: StateType = {
  dealerList: [],
  listStatus: "idle",
  dealer: undefined,
  dealerStatus: "idle",
  error: null,
};

const dealersSlice = createSlice({
  name: "dealers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealers.fulfilled, (state, action) => {
        state.listStatus = "succeeded";
        state.dealerList = action.payload;
      })
      .addCase(fetchDealers.pending, (state) => {
        state.listStatus = "loading";
        state.error = null;
      })
      .addCase(fetchDealers.rejected, (state, action) => {
        state.listStatus = "failed";
        state.error = action.error.message ?? null;
        state.dealerList = [];
      });
    builder
      .addCase(patchActiveDealer.fulfilled, (state) => {
        window.location.reload();
      })
      .addCase(patchActiveDealer.rejected, (state, action) => {
        toast.error(action.error.message ?? "Failed to change active dealer");
      });
  },
});

export default dealersSlice.reducer;
