import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routerPath } from "Routes/allRoutes";

enum MenuItem {
  Home = "home",
  Payments = "payments",
  Payment = "payment",
  NewPayment = "newPayment",
  PaymentRequest = "paymentRequest",
  ManuelPayment = "manuelPayment",
  ManuelPos = "manuelPos",
  LinkPayment = "linkPayment",
  Transactions = "transactions",
  Transaction = "transaction",
  Refund = "refund",
  Dealers = "dealers",
  Dealer = "dealer",
  DealerUser = "dealerUser",
}

type TypeMenuItem = {
  id: MenuItem;
  label: string;
  link: string;
  icon?: string;
  click?: (e: any) => void;
  stateVariables?: boolean;
  parentId?: MenuItem;
  subItems?: Array<TypeMenuItem>;
  isHeader?: boolean;
};

const Navdata = () => {
  const history = useNavigate();

  const [iscurrentState, setIscurrentState] = useState<MenuItem>(MenuItem.Home);

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history, iscurrentState]);

  const menuItems: Array<TypeMenuItem> = [
    // {
    //   label: "modules",
    //   isHeader: true,
    // },
    {
      id: MenuItem.Home,
      label: "Dashboard",
      icon: "ri-home-4-fill",
      link: routerPath.Dashboard,
      click: function (e: any) {
        e.preventDefault();
        // setIsPaymentLinks(!isPaymentLinks);
        setIscurrentState(MenuItem.Home);
        updateIconSidebar(e);
      },
      stateVariables: iscurrentState === MenuItem.Home,
    },
    {
      id: MenuItem.Payments,
      label: "Payment Management",
      icon: "ri-money-dollar-circle-line",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIscurrentState(MenuItem.Payments);
        updateIconSidebar(e);
      },
      stateVariables: iscurrentState === MenuItem.Payments,
      subItems: [
        {
          id: MenuItem.ManuelPayment,
          parentId: MenuItem.Payments,
          label: "Receive Manual Payment",
          link: routerPath.ManualPayment,
          click: function (e: any) {
            e.preventDefault();
            setIscurrentState(MenuItem.NewPayment);
            updateIconSidebar(e);
          },
          stateVariables: iscurrentState === MenuItem.ManuelPayment,
        },
        {
          id: MenuItem.LinkPayment,
          parentId: MenuItem.Payments,
          label: "Receive Payment via Link",
          link: routerPath.LinkPayment,
          click: function (e: any) {
            e.preventDefault();
            setIscurrentState(MenuItem.NewPayment);
            updateIconSidebar(e);
          },
          stateVariables: iscurrentState === MenuItem.NewPayment,
        },
        {
          id: MenuItem.Payment,
          parentId: MenuItem.Payments,
          label: "Payments",
          // icon: "ri-money-dollar-circle-line",
          link: routerPath.Payment,
          click: function (e: any) {
            e.preventDefault();
            setIscurrentState(MenuItem.Payment);
            updateIconSidebar(e);
          },
          stateVariables: iscurrentState === MenuItem.Payment,
        },
      ],
    },
    {
      id: MenuItem.Transactions,
      label: "Transactions",
      icon: "ri-layout-2-fill",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIscurrentState(MenuItem.Transactions);
        updateIconSidebar(e);
      },
      stateVariables: iscurrentState === MenuItem.Transactions,
      subItems: [
        {
          id: MenuItem.Transaction,
          parentId: MenuItem.Transactions,
          label: "All Transactions",
          link: routerPath.Transaction,
          click: function (e: any) {
            e.preventDefault();
            setIscurrentState(MenuItem.Transaction);
            updateIconSidebar(e);
          },
          stateVariables: iscurrentState === MenuItem.Transaction,
        },
        {
          id: MenuItem.Refund,
          parentId: MenuItem.Transactions,
          label: "Refund Transactions",
          link: routerPath.Refund,
          click: function (e: any) {
            e.preventDefault();
            setIscurrentState(MenuItem.Refund);
            updateIconSidebar(e);
          },
          stateVariables: iscurrentState === MenuItem.Refund,
        },
      ],
    },
    {
      id: MenuItem.Dealers,
      label: "Dealers",
      icon: "ri-store-2-line",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIscurrentState(MenuItem.Dealers);
        updateIconSidebar(e);
      },
      stateVariables: iscurrentState === MenuItem.Dealers,
      subItems: [
        {
          id: MenuItem.DealerUser,
          parentId: MenuItem.Dealers,
          label: "Users",
          link: routerPath.DealerUser,
          click: function (e: any) {
            e.preventDefault();
            setIscurrentState(MenuItem.Transaction);
            updateIconSidebar(e);
          },
          stateVariables: iscurrentState === MenuItem.DealerUser,
        },
        {
          id: MenuItem.Dealer,
          parentId: MenuItem.Dealers,
          label: "Dealers",
          link: routerPath.Dealer,
          click: function (e: any) {
            e.preventDefault();
            setIscurrentState(MenuItem.Dealer);
            updateIconSidebar(e);
          },
          stateVariables: iscurrentState === MenuItem.Dealer,
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems as any}</React.Fragment>;
};
export default Navdata;
