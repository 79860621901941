import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import MainDealer from "./MainDealer";
import SubDealerList from "./SubDealerList";
import { connect } from "react-redux";

interface DealerProps {
  list: any[];
}

const Dealer: React.FC<DealerProps> = ({ list }) => {
  const [mainDealer, setMainDealer] = useState<any>({});
  useEffect(() => {
    if (Array.isArray(list) && list.length > 0) {
      const _mainDealer = list.find((d) => d.is_main_merchant === true);
      setMainDealer(_mainDealer);
    } else {
      setMainDealer({});
    }
  }, [list]);

  return (
    <div className="page-content">
      <Container fluid>
        <MainDealer dealer={mainDealer} />
        <SubDealerList />
      </Container>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  list: state.Dealer.list,
});

export default connect(mapStateToProps)(Dealer);
