import { createSlice } from "@reduxjs/toolkit";
import { getCreditCardInstallmentOptions } from "./creditcardThunk";
import { toast } from "react-toastify";

export const initialState = {
  creditCards: [],
  error: "",
  loading: false,
  errorMsg: false,
};

const creditCardSlice = createSlice({
  name: "creditcard",
  initialState,
  reducers: {
    changeCreditCardInstallmentLoading(
      state: { loading: boolean },
      action: { payload: boolean }
    ) {
      state.loading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      getCreditCardInstallmentOptions.fulfilled,
      (state: any, action: any) => {
        state.creditCards = action.payload;
        state.loading = false;
        state.errorMsg = false;
      }
    );
    builder.addCase(
      getCreditCardInstallmentOptions.rejected,
      (state: any, action: any) => {
        state.creditCards = [];
        state.loading = false;
        state.errorMsg = true;
        let message: string = "Unknown error";
        if (action?.payload?.error?.message) {
          message = action.payload.error.message;
        }
        toast.error(message, {
          autoClose: 5000,
          onClose: () => {
            window.location.reload();
          },
        });
      }
    );
  },
});

export const { changeCreditCardInstallmentLoading: changeInstallmentLoading } =
  creditCardSlice.actions;
export default creditCardSlice.reducer;
