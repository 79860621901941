import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ParticlesAuth from "../ParticlesAuth";
import Logo from "../../../assets/images/pospazar.png";
import { Trans } from "react-i18next";
import { routerPath } from "Routes/allRoutes";

const PaymentFailed = () => {
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content mt-lg-5">
            <Container className="d-flex align-items-center justify-content-center min-vh-100">
              <Row className="text-center">
                <Col lg={12}>
                  <div className="pt-4">
                    <div className="mb-5">
                      <img
                        src={Logo}
                        alt=""
                        className="error-basic-img move-animation"
                        style={{ transform: "scale(5)" }}
                      />
                    </div>
                    <div className="mt-4">
                      <i
                        className="ri-close-circle-fill text-danger"
                        style={{ fontSize: "5rem" }}
                      ></i>
                      <h1 className="display-1 fw-medium">
                        <Trans i18nKey="Payment Failed" />
                      </h1>
                      <div className="mt-4"></div>
                      <p className="text-muted mb-4">
                        <Trans i18nKey="Click to go to my transactions page." />
                      </p>
                      <Link
                        to={routerPath.Transaction}
                        className="btn btn-secondary"
                      >
                        <i className="ri-layout-2-fill me-1"></i>
                        <Trans i18nKey="Transactions" />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </React.Fragment>
  );
};

export default PaymentFailed;
