import { AppEnv } from "AppEnv";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const api = AppEnv.ApiUrl;

// default
axios.defaults.baseURL = api;
axios.defaults.withCredentials = AppEnv.WithCredentials;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
};

// content type
const auth = localStorage.getItem("authUser");
const tokens: { access: string; refresh: string } = auth
  ? JSON.parse(auth)
  : null;
setAuthorization(tokens?.access);

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== "/panel/token/refresh/"
    ) {
      originalRequest._retry = true;
      try {
        const { data } = await axios.post(
          "/panel/token/refresh/",
          { refresh: tokens?.refresh },
          { withCredentials: AppEnv.WithCredentials }
        );
        const { access: accessToken } = data;
        tokens.access = accessToken;
        localStorage.setItem("authUser", JSON.stringify(tokens));
        setAuthorization(accessToken);
        originalRequest.headers["Authorization"] = "Bearer " + accessToken;
        return axios(originalRequest);
      } catch (_error) {
        localStorage.removeItem("authUser");
        window.location.href = "/login";
        return Promise.reject(_error);
      }
    } else if (error.response?.status === 401 && originalRequest._retry) {
      localStorage.removeItem("authUser");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = <T>(url: string, params?: any): Promise<AxiosResponse<T>> => {
    let response: Promise<AxiosResponse>;

    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).forEach((key) => {
        paramKeys.push(key + "=" + encodeURIComponent(params[key]));
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get<T>(`${url}?${queryString}`);
    } else {
      response = axios.get<T>(url);
    }

    return response;
  };
  /**
   * post given data to url
   */
  post = <T>(url: string, data?: any) => {
    return axios.post<T>(url, data);
  };
  /**
   * Updates data
   */
  patch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  put = (url: string, data: any) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    return axios.delete(url, { ...config });
  };

  /**
   * Fetches data from given url without Authorization header
   */
  publicGet = <T>(url: string, params?: any): Promise<AxiosResponse<T>> => {
    let response: Promise<AxiosResponse>;

    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).forEach((key) => {
        paramKeys.push(key + "=" + encodeURIComponent(params[key]));
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get<T>(`${url}?${queryString}`, {
        headers: { Authorization: undefined },
      });
    } else {
      response = axios.get<T>(url, {
        headers: { Authorization: undefined },
      });
    }

    return response;
  };

  /**
   * post given data to url without Authorization header
   */
  publicPost = (url: string, data?: any): Promise<AxiosResponse> => {
    return axios.post(url, data, {
      headers: { Authorization: undefined },
    });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
