import TableContainer from "Components/Common/TableContainer";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const SubDealerList = ({ subDealerData }: any) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const columns = useMemo(
    () => [
      {
        header: "Adı",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Toplam Satış",
        accessorKey: "totalSales",
        enableColumnFilter: false,
        cell: (cell: any) => {
          // return (
          //   Number(cell.getValue()).toFixed(2) +
          //   " " +
          //   cell.row.original.currency
          // );
          return "0₺";
        },
      },
      {
        header: "Telefon",
        accessorKey: "phoneNumber",
        enableColumnFilter: false,
      },
      {
        header: "Kayıt Tarihi",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = new Date(cell.getValue());
          return date.toLocaleDateString();
        },
      },
      {
        header: "",
        enableColumnFilter: false,
        accessorKey: "id",
        cell: (cell: any) => {
          return (
            <Link to={`/sub-dealers/${cell.getValue()}/details`}>Details</Link>
          );
        },
      },
    ],
    []
  );

  return (
    <Card>
      <CardBody>
        <Button color="primary" onClick={toggleModal}>
          Yeni Şube Ekle
        </Button>
        <TableContainer
          columns={columns || []}
          data={subDealerData || []}
          customPageSize={5}
          SearchPlaceholder="Search Sub-Dealers..."
        />
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Yeni Şube Ekle</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="subDealerName">Şube Adı</Label>
                <Input type="text" id="subDealerName" placeholder="Şube Adı" />
              </FormGroup>
              <FormGroup>
                <Label for="subDealerType">Şube Tipi</Label>
                <Input type="select" id="subDealerType">
                  <option value="0">Bayi</option>
                  <option value="1">Alt Bayi</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="totalSales">Toplam Satış</Label>
                <Input
                  type="number"
                  id="totalSales"
                  placeholder="Toplam Satış"
                />
              </FormGroup>
              <FormGroup>
                <Label for="currency">Para Birimi</Label>
                <Input type="select" id="currency">
                  <option value="$">$</option>
                  <option value="€">€</option>
                  <option value="£">£</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="registrationDate">Kayıt Tarihi</Label>
                <Input type="date" id="registrationDate" />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleModal}>
              Kaydet
            </Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              İptal
            </Button>
          </ModalFooter>
        </Modal>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  subDealerData: state.Dealer.list,
});

export default connect(mapStateToProps)(SubDealerList);
