import { APIClient } from "./inner_api";

const api = new APIClient();

//#region Auth
export const login = async (body: PosPazarTypes.LoginPaylod) =>
  api.post<PosPazarTypes.LoginResponse>("/panel/token/obtain/", body);
export const refreshToken = async (body: { refresh: String }) =>
  api.post("/panel/token/refresh", body);
export const setBlackList = async (body: { refresh: String }) =>
  api.post("/panel/token/blacklist/", body);
export const forgotPassword = async (body: { email: String }) =>
  api.post("/panel/forgot-password", body);
//#endregion Auth

//#region Core
export const getCoreCountries = async () =>
  api.publicGet<Array<PosPazarTypes.Country>>("/panel/countries/");
export const getCoreCities = async () =>
  api.publicGet<Array<PosPazarTypes.City>>(`/panel/cities/?country_code=tr`);
export const getCoreTownships = async (city_id: number) =>
  api.publicGet<Array<PosPazarTypes.Township>>(
    `/panel/townships/?city_id=${city_id}`
  );
export const getCoreProviders = async () =>
  api.publicGet<Array<PosPazarTypes.PosProvider>>("/panel/pos-providers/");
export const postMerchantApplication = async (
  body: PosPazarTypes.MerchantApplicationBody
) => api.publicPost("/panel/application/", body);
//#endregion Core

//#region Dashboard
export const getDashboardData = async () =>
  api.get<PosPazarTypes.DashboardReducer>("/panel/dashboard/");
export const getMe = async () =>
  api.get<PosPazarTypes.UserInfoReducer>("/panel/me/");
export const getDealers = async () =>
  api.get<Array<PosPazarTypes.DealerList>>("/panel/merchant/");
export const patchActiveDealerReq = async (body: { merchant_id: number }) =>
  api.patch("/panel/merchant/select/", body);
//#endregion Dashboard

//#region Pos Providers
export const getPosProviders = async () =>
  api.get<Array<PosPazarTypes.PosProvider>>("/panel/pos-provider/");
export const getInstallments = async (
  provider: string,
  amount: number,
  binNumber: string
) =>
  api.get(
    `/panel/pos-provider/${provider}/installments/?amount=${amount}&bin_number=${binNumber}`
  );
export const post3DPayment = async (
  provider: string,
  body: {
    amount: number;
    installment: number;
    card_holder_name: string;
    card_no: string;
    expire_date: string;
    cvv: string;
  }
) => api.post(`/panel/pos-provider/${provider}/process-threed-payment/`, body);
//#endregion Pos Providers

//#region Payment Links
export const getPaymentLinks = async (page?: number) =>
  api.get<PosPazarTypes.ApiPagination<PosPazarTypes.PaymentLinkItem>>(
    `/panel/payment-link/${page && page !== 1 ? `?page=${page}` : ""}`
  );
export const postPaymentLinkReq = async (
  body: PosPazarTypes.PaymentLinkPayload
) => api.post("/panel/payment-link/", body);
export const getPaymentLinksPaginated = async (page = 1, limit = 10) =>
  api.get(`/panel/payment-links?page=${page}&page_size=${limit}`);
export const getPaymentLinkDetails = async (uuid: String) =>
  api.get<PosPazarTypes.PaymentLink>(`/panel/payment-link/${uuid}/`);
export const patchPaymentLink = async (uuid: String, body: any) =>
  api.patch(`/panel/payment-link/${uuid}/`, body);
export const deletePaymentLinkReq = async (uuid: String) =>
  api.delete(`/panel/payment-link/${uuid}/`);
//#endregion Payment Links

//#region Link Payment
export const getLinkPayment = async (uuid: string) =>
  api.get<PosPazarTypes.LinkPayment>(`/panel/link-payment/${uuid}/`);
export const postLinkPayment = async (
  uuid: string,
  body: PosPazarTypes.LinkPaymentPayload
) => api.post<any>(`/panel/link-payment/${uuid}/`, body);
//#endregion Link Payment

//#region Transactions
export const getTransactionsReq = async (page?: number) =>
  api.get<PosPazarTypes.ApiPagination<PosPazarTypes.TransactionListItem>>(
    `/panel/transaction${page && page !== 1 ? `?page=${page}` : ""}`
  );
export const getTransactionReq = async (uniq: string) =>
  api.get<PosPazarTypes.TransactionDetail>(`/panel/transaction/${uniq}`);
//#endregion Transactions
