import { useState } from "react";
import { Col, Row, Container, Label, Input } from "reactstrap";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import "react-credit-cards/lib/styles.scss";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import { TFunction } from "i18next";

const CreditCard = (props: {
  value: PosPazarTypes.CardInfoProps;
  onChange: (arg0: PosPazarTypes.CardInfoProps) => void;
  isValid: (arg0: boolean) => void;
  t: TFunction;
}) => {
  const [state, setState] = useState<PosPazarTypes.CardInfoProps>({
    cardNumber: "",
    cardHolder: "",
    expiry: "",
    cvc: "",
  });

  const [focus, setFocus] = useState("");

  const validateState = (newState: PosPazarTypes.CardInfoProps) => {
    const { cardNumber, cardHolder, expiry, cvc } = newState;
    const isValidCardNumber = cardNumber.length === 16;
    const isValidCardHolder = cardHolder.length > 0;
    const isValidExpiry = expiry.length === 5; // MM/YY format
    const isValidCvc = cvc.length === 3;

    const isValid =
      isValidCardNumber && isValidCardHolder && isValidExpiry && isValidCvc;
    props.isValid(isValid);
    if (isValid) {
      props.onChange(newState);
    }
  };

  const onChangeHandler = (e: any, name: any) => {
    const newState = { ...state, [name]: e.target.value };
    setState(newState);
    validateState(newState);
  };

  const handleInputFocus = (e: any) => {
    setFocus(e.target.name);
  };

  return (
    <Container className="px-0">
      <Row className="justify-content-center">
        <Col
          lg={6}
          className="order-lg-2 mb-3 d-flex align-items-center justify-content-center"
        >
          <Cards
            cvc={state.cvc}
            expiry={state.expiry}
            focused={focus}
            name={state.cardHolder}
            number={state.cardNumber}
            i18nIsDynamicList={true}
          />
        </Col>
        <Col lg={6} className="order-lg-1">
          <div className="form-container active">
            <div id="card-form-elem">
              <div className="mb-3">
                <Label htmlFor="card-number-input" className="form-label">
                  {props.t("Card Number")}
                </Label>
                <Input
                  maxLength={16}
                  value={state.cardNumber}
                  onFocus={(e) => handleInputFocus(e)}
                  onChange={(e) => onChangeHandler(e, "cardNumber")}
                  name="cardNumber"
                  className="form-control"
                  placeholder={props.t("Card Number")}
                  type="tel"
                  id="card-number-input"
                />
              </div>
              <div className="mb-3">
                <Label htmlFor="card-name-input" className="form-label">
                  {props.t("Card Holder")}
                </Label>
                <Input
                  maxLength={25}
                  value={state.cardHolder}
                  onFocus={(e) => handleInputFocus(e)}
                  onChange={(e) => onChangeHandler(e, "cardHolder")}
                  className="form-control"
                  name="cardHolder"
                  placeholder={props.t("Name") + " " + props.t("Surname")}
                  type="text"
                  id="card-name-input"
                />
              </div>
              <Row>
                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="card-expiry-input" className="form-label">
                      {props.t("Expiry Date")}
                    </Label>
                    <Cleave
                      placeholder="MM/YY"
                      options={{
                        date: true,
                        datePattern: ["m", "y"],
                      }}
                      value={state.expiry}
                      name="expiry"
                      onChange={(e: any) => onChangeHandler(e, "expiry")}
                      onFocus={(e: any) => handleInputFocus(e)}
                      className="form-control"
                      id="card-expiry-input"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="card-cvc-input" className="form-label">
                      CVC
                    </Label>
                    <input
                      value={state.cvc}
                      onFocus={(e) => handleInputFocus(e)}
                      onChange={(e) => onChangeHandler(e, "cvc")}
                      name="cvc"
                      className="form-control"
                      placeholder="CVC"
                      maxLength={3}
                      type="text"
                      id="card-cvc-input"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CreditCard;
