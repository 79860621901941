type ForeginCurrency = {
  Isim: string;
  CurrencyName: string;
  BanknoteBuying: number;
  BanknoteSelling: number;
  ForexBuying: number;
  ForexSelling: number;
  [key: string]: any;
};

const mappingCurrency = (
  currency: ForeginCurrency
): PosPazarTypes.CurrencyRate | null => {
  const crr = {
    currency: currency.CurrencyName,
    buying: currency.ForexBuying,
    selling: currency.ForexSelling,
  } as PosPazarTypes.CurrencyRate;
  switch (currency.CurrencyName) {
    case "US DOLLAR":
      crr.code = "USD";
      crr.symbol = "$";
      crr.order = 1;
      break;
    case "EURO":
      crr.code = "EUR";
      crr.symbol = "€";
      crr.order = 2;
      break;
    case "AUSTRALIAN DOLLAR":
      crr.code = "AUD";
      crr.symbol = "A$";
      crr.order = 6;
      break;
    case "DANISH KRONE":
      crr.code = "DKK";
      crr.symbol = "kr";
      crr.order = 20;
      break;
    case "POUND STERLING":
      crr.code = "GBP";
      crr.symbol = "£";
      crr.order = 4;
      break;
    case "SWISS FRANK":
      crr.code = "CHF";
      crr.symbol = "CHF";
      crr.order = 8;
      break;
    case "SWEDISH KRONA":
      crr.code = "SEK";
      crr.symbol = "kr";
      crr.order = 11;
      break;
    case "CANADIAN DOLLAR":
      crr.code = "CAD";
      crr.symbol = "C$";
      crr.order = 7;
      break;
    case "KUWAITI DINAR":
      crr.code = "KWD";
      crr.symbol = "د.ك";
      crr.order = 106;
      break;
    case "NORWEGIAN KRONE":
      crr.code = "NOK";
      crr.symbol = "kr";
      crr.order = 13;
      break;
    case "SAUDI RIYAL":
      crr.code = "SAR";
      crr.symbol = "ر.س";
      crr.order = 30;
      break;
    case "JAPANESE YEN":
      crr.code = "JPY";
      crr.symbol = "¥";
      crr.order = 3;
      break;
    case "BULGARIAN LEV":
      crr.code = "BGN";
      crr.symbol = "лв";
      crr.order = 102;
      break;
    case "NEW LEU":
      crr.code = "RON";
      crr.symbol = "lei";
      crr.order = 35;
      break;
    case "RUSSIAN RUBLE":
      crr.code = "RUB";
      crr.symbol = "₽";
      crr.order = 34;
      break;
    case "IRANIAN RIAL":
      crr.code = "IRR";
      crr.symbol = "﷼";
      crr.order = 104;
      break;
    case "CHINESE RENMINBI":
      crr.code = "CNY";
      crr.symbol = "¥";
      crr.order = 5;
      break;
    case "PAKISTANI RUPEE":
      crr.code = "PKR";
      crr.symbol = "Rs";
      crr.order = 108;
      break;
    case "QATARI RIAL":
      crr.code = "QAR";
      crr.symbol = "ر.ق";
      crr.order = 110;
      break;
    case "SOUTH KOREAN WON":
      crr.code = "KRW";
      crr.symbol = "₩";
      crr.order = 12;
      break;
    case "AZERBAIJANI NEW MANAT":
      crr.code = "AZN";
      crr.symbol = "₼";
      crr.order = 100;
      break;
    case "UNITED ARAB EMIRATES DIRHAM":
      crr.code = "AED";
      crr.symbol = "د.إ";
      crr.order = 26;
      break;
    default:
      return null;
  }
  return crr;
};

/** https://hasanadiguzel.com.tr/api/kurgetir adresine gore yapilmistir */
export const normalizeCurrencyData = (data: {
  TCMB_AnlikKurBilgileri: Array<ForeginCurrency>;
  [key: string]: any;
}): PosPazarTypes.CurrencyRate[] => {
  const currencyData: PosPazarTypes.CurrencyRate[] = [];
  data.TCMB_AnlikKurBilgileri.forEach((currency) => {
    const crr = mappingCurrency(currency);
    if (crr) currencyData.push(crr);
  });
  return currencyData.sort((a, b) => a.order - b.order);
};
