import { combineReducers } from "redux";

import LayoutReducer from "./layouts/reducer";
import AuthReducer from "./auth/authReducer";
import CoreReducer from "./core/coreReducer";
import CreditCardReducer from "./creditcard/creditcardReducer";
import DashboardReducer from "./dashboard/dashboardReducer";
import DealerReducer from "./dealer/dealerReducer";
import InstallmentReducer from "./installment/installmentReducer";
import PaymentLinkReducer from "./paymentLink/paymentLinkReducer";
import PaymentRequestReducer from "./paymentRequest/paymentRequestReducer";
import PosProviderReducer from "./posprovider/posproviderReducer";
import TransactionReducer from "./transaction/transactionReducer";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Core: CoreReducer,
  CreditCard: CreditCardReducer,
  Dashboard: DashboardReducer,
  Dealer: DealerReducer,
  Installment: InstallmentReducer,
  Layout: LayoutReducer,
  PaymentLink: PaymentLinkReducer,
  PaymentRequest: PaymentRequestReducer,
  PosProvider: PosProviderReducer,
  Transaction: TransactionReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
