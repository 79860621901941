import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTransactionReq, getTransactionsReq } from "helpers/api/inner_urls";

export const getTransactions = createAsyncThunk(
  "transaction/getTransactions",
  async (pageNumber?: number) => {
    const { data } = await getTransactionsReq(pageNumber);
    return data;
  }
);

export const getTransaction = createAsyncThunk(
  "transaction/getTransaction",
  async (uniq: string) => {
    const { data } = await getTransactionReq(uniq);
    return data;
  }
);
