import { TFunction } from "i18next";

const InstallmentOptions = ({
  installments,
  selectedInstallment,
  onChange,
  t,
}: {
  installments: any[];
  selectedInstallment: number;
  onChange: (installmentId: number) => void;
  t: TFunction;
}) => {
  return (
    <div className="installment-options mt-4">
      <h5>{t("Installment Options")}</h5>
      <div className="d-flex flex-wrap">
        {installments.map((option: any) => (
          <div
            key={`installment-${option.installment}`}
            className={`p-3 m-2 border rounded ${
              selectedInstallment === option.installment
                ? "bg-primary text-white"
                : "bg-light"
            }`}
            style={{
              cursor: "pointer",
              width: "calc(25% - 16px)", // 4 divs per row with some margin
              textAlign: "center",
            }}
            onClick={() => onChange(option.installment)}
          >
            <span className="text-lowercase">
              {option.installment} {t("Installment")}
            </span>
            <br />
            <strong>
              {t("Total")} {option.amount.toFixed(2)} TL
            </strong>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstallmentOptions;
