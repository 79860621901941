import { AppEnv } from "AppEnv";
import externalApi from "./external_api";

export const postUploadFile = async (body: any) =>
  externalApi.post<PosPazarTypes.FileUploadResponse>(
    `${AppEnv.TetraxUrl}/api/upload`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: false,
    }
  );

export const getCurrenciesRatesReq = async () =>
  externalApi.get<any>("https://hasanadiguzel.com.tr/api/kurgetir");
