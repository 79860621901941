import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

class ExternalApi {
  private client: AxiosInstance;
  constructor() {
    this.client = axios.create();
  }

  get = <T>(url: string, config?: AxiosRequestConfig<any>) => {
    return this.client.get<T>(url, config);
  };

  post = <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<AxiosResponse<T>> => {
    return this.client.post<T>(url, data, config);
  };
}

const externalApiInstance = new ExternalApi();
export default externalApiInstance;
