/* eslint-disable react-hooks/exhaustive-deps */
import TableContainer from "Components/Common/TableContainer";
import { useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import RefundModal from "./RefundModal";

const Refund = () => {
  const defaultTable = [
    {
      id: "01",
      name: "Jonathan Doe",
      email: "jonathan@example.com",
      amount: 1000,
      currency: "₺",
      date: "2021-09-01T00:00:00Z",
      status: 0,
    },
    {
      id: "02",
      name: "Alice Smith",
      email: "alice@example.com",
      amount: 2000,
      currency: "₺",
      date: "2021-09-02T00:00:00Z",
      status: 1,
    },
    {
      id: "03",
      name: "Bob Johnson",
      email: "bob@example.com",
      amount: 3000,
      currency: "₺",
      date: "2021-09-03T00:00:00Z",
      status: 0,
    },
    {
      id: "04",
      name: "Charlie Brown",
      email: "charlie@example.com",
      amount: 4000,
      currency: "₺",
      date: "2021-09-04T00:00:00Z",
      status: 2,
    },
    {
      id: "05",
      name: "Diana Prince",
      email: "diana@example.com",
      amount: 5000,
      currency: "₺",
      date: "2021-09-05T00:00:00Z",
      status: 0,
    },
  ];
  const columns = useMemo(
    () => [
      {
        header: "Ad",
        cell: (cell: any) => {
          return <span className="fw-semibold">{cell.getValue()}</span>;
        },
        accessorKey: "name",
        enableColumnFilter: false,
      },

      {
        header: "E-posta",
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: "Tutar",
        accessorKey: "amount",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="fw-semibold">
              {cell.row.original.amount} {cell.row.original.currency}
            </span>
          );
        },
      },
      {
        header: "Tarih",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = new Date(cell.row.original.date);
          return (
            <span className="fw-semibold">
              {date.toLocaleDateString()} {date.toLocaleTimeString()}
            </span>
          );
        },
      },
      {
        header: "Statü",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const status = cell.row.original.status;
          let statusText = "";
          let statusColor = "";
          switch (status) {
            case 0:
              statusText = "Beklemede";
              statusColor = "warning";
              break;
            case 1:
              statusText = "Onaylandı";
              statusColor = "success";
              break;
            case 2:
              statusText = "Reddedildi";
              statusColor = "danger";
              break;
            default:
              statusText = "Beklemede";
              statusColor = "warning";
              break;
          }
          return (
            <Badge color={statusColor} pill={true}>
              {statusText}
            </Badge>
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div className="d-flex justify-content-center gap-3">
              <Button
                className="btn btn-primary"
                onClick={() => handleDetailClick(cell.row.original)}
              >
                Detay
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const handleDetailClick = (row: any) => {
    setSelectedRow(row);
    setIsRefundModalOpen(!isRefundModalOpen);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="card-height-100">
                <CardHeader>İadeler</CardHeader>
                <CardBody>
                  <TableContainer
                    columns={columns || []}
                    data={defaultTable || []}
                    customPageSize={5}
                    isGlobalFilter={true}
                    SearchPlaceholder="Search..."
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <RefundModal
        isOpen={isRefundModalOpen}
        toggle={() => setIsRefundModalOpen(!isRefundModalOpen)}
        refundData={selectedRow}
      />
    </>
  );
};

export default Refund;
