import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Col, Input, Label, Row, FormFeedback } from "reactstrap";
import { getAllCities, getAllProviders, getAllTownships } from "slices/thunks";
import { t } from "i18next";
import {
  isEmail,
  isIdentityNumber,
  isPhoneNumber,
} from "helpers/data/validate";
import { RootState } from "slices";

interface CustomProps {
  value: any;
  onChange: (values: any) => void;
  isValid: (isValid: boolean) => void;
}

const CustomerInfo = (props: PropsFromRedux & CustomProps) => {
  const {
    onChange,
    isValid,
    providers,
    cities,
    townships,
    fetchProviders,
    fetchCities,
    fetchTownships,
  } = props;
  const [provider, setProvider] = useState<string>("-1");
  const [amount, setAmount] = useState<string>("");
  const [currency, setCurrency] = useState<string>("TRY");
  const [userName, setUserName] = useState<string>("");
  const [identityNumber, setIdentityNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [cityId, setCityId] = useState<number>(0);
  const [townshipId, setTownshipId] = useState<number>(0);

  useEffect(() => {
    fetchProviders();
    fetchCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (providers.length > 0) {
      setProvider(providers[0].key);
    }
  }, [providers]);

  useEffect(() => {
    if (cities.length > 0) {
      setCityId(cities[0].id);
    } else {
      setCityId(0);
    }
  }, [cities]);

  useEffect(() => {
    if (cityId !== 0) {
      fetchTownships(cityId);
    }
  }, [cityId, fetchTownships]);

  useEffect(() => {
    if (townships.length > 0) {
      setTownshipId(townships[0].id);
    } else {
      setTownshipId(0);
    }
  }, [townships]);

  const isProviderSelected = provider !== "-1";

  const isValidForm = useCallback(() => {
    const newErrors: any = {};
    if (provider === "-1")
      newErrors.provider = props.t("POS provider is required");
    if (amount.trim() === "") newErrors.amount = props.t("Amount is required");
    if (currency.trim() === "")
      newErrors.currency = props.t("Currency is required");
    if (userName.trim() === "")
      newErrors.userName = props.t("Name is required");
    const isIdentityNumberValid = isIdentityNumber(identityNumber);
    if (!isIdentityNumberValid.isValid)
      newErrors.identityNumber = props.t(isIdentityNumberValid.message);
    const isEmailValid = isEmail(email);
    if (!isEmailValid.isValid) newErrors.email = props.t(isEmailValid.message);
    const isPhoneNumberValid = isPhoneNumber(phoneNumber);
    if (!isPhoneNumberValid.isValid)
      newErrors.phoneNumber = props.t(isPhoneNumberValid.message);
    if (address.trim() === "")
      newErrors.address = props.t("Address is required");

    return newErrors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    provider,
    amount,
    currency,
    userName,
    identityNumber,
    email,
    phoneNumber,
    address,
    props,
  ]);

  useEffect(() => {
    const newErrors = isValidForm();
    isValid(Object.keys(newErrors).length === 0);
  }, [
    provider,
    amount,
    currency,
    userName,
    identityNumber,
    email,
    phoneNumber,
    address,
    description,
    isValid,
    isValidForm,
  ]);

  const formValues = useMemo(
    () => ({
      provider,
      amount,
      currency,
      userName,
      identityNumber,
      email,
      phoneNumber,
      address,
      description,
    }),
    [
      provider,
      amount,
      currency,
      userName,
      identityNumber,
      email,
      phoneNumber,
      address,
      description,
    ]
  );

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<any>>, field: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: undefined }));
    };

  const handleSubmit = () => {
    const newErrors = isValidForm();
    setErrors(newErrors);
    isValid(Object.keys(newErrors).length === 0);
    if (Object.keys(newErrors).length === 0) {
      onChange(formValues);
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col md="12">
            <div className="mb-3">
              <Label for="provider">{props.t("POS Provider")}</Label>
              <Input
                type="select"
                className="form-select"
                id="provider"
                name="provider"
                value={provider}
                onChange={(e) => {
                  setProvider(e.target.value);
                  setErrors((prevErrors: any) => ({
                    ...prevErrors,
                    provider: undefined,
                  }));
                }}
                invalid={!!errors.provider}
              >
                <option value="-1" hidden>
                  {props.t("Select")}
                </option>
                {providers.map((provider: any) => {
                  return (
                    <option key={provider.key} value={provider.key}>
                      {provider.name}
                    </option>
                  );
                })}
              </Input>
              {errors.provider && (
                <FormFeedback>{errors.provider}</FormFeedback>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="amount">
                {props.t("Amount")}
              </Label>
              <Input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                placeholder={props.t("Amount")}
                disabled={!isProviderSelected}
                value={amount}
                onChange={handleInputChange(setAmount, "amount")}
                invalid={!!errors.amount}
              />
              {errors.amount && <FormFeedback>{errors.amount}</FormFeedback>}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="currency">
                {props.t("Currency")}
              </Label>
              <Input
                type="select"
                className="form-select"
                id="currency"
                name="currency"
                disabled={!isProviderSelected}
                value={currency}
                onChange={handleInputChange(setCurrency, "currency")}
                invalid={!!errors.currency}
              >
                <option value="TRY" hidden>
                  TRY
                </option>
              </Input>
              {errors.currency && (
                <FormFeedback>{errors.currency}</FormFeedback>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="user_name">
                {props.t("Name") + " " + props.t("Surname")}
              </Label>
              <Input
                type="text"
                className="form-control"
                id="user_name"
                name="user_name"
                placeholder={props.t("Name") + "/" + props.t("Surname")}
                disabled={!isProviderSelected}
                value={userName}
                onChange={handleInputChange(setUserName, "userName")}
                invalid={!!errors.userName}
              />
              {errors.userName && (
                <FormFeedback>{errors.userName}</FormFeedback>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="identity_number">
                {props.t("Identity Number")}
              </Label>
              <Input
                type="text"
                className="form-control"
                id="identity_number"
                name="identity_number"
                placeholder={props.t("Identity Number")}
                disabled={!isProviderSelected}
                value={identityNumber}
                onChange={handleInputChange(
                  setIdentityNumber,
                  "identityNumber"
                )}
                invalid={!!errors.identityNumber}
              />
              {errors.identityNumber && (
                <FormFeedback>{errors.identityNumber}</FormFeedback>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="email">
                {props.t("Email")}
              </Label>
              <Input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder={props.t("Email")}
                disabled={!isProviderSelected}
                value={email}
                onChange={handleInputChange(setEmail, "email")}
                invalid={!!errors.email}
              />
              {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="phone_number">
                {props.t("Phone Number")}
              </Label>
              <Input
                type="text"
                className="form-control"
                id="phone_number"
                name="phone_number"
                placeholder={props.t("Phone Number")}
                disabled={!isProviderSelected}
                value={phoneNumber}
                onChange={handleInputChange(setPhoneNumber, "phoneNumber")}
                invalid={!!errors.phoneNumber}
              />
              {errors.phoneNumber && (
                <FormFeedback>{errors.phoneNumber}</FormFeedback>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label for="cityId">{props.t("City")}</Label>
              <Input
                type="select"
                id="cityId"
                name="cityId"
                value={cityId}
                onChange={handleInputChange(setCityId, "cityId")}
                invalid={Boolean(errors.cityId)}
              >
                <option value="0" hidden>
                  {props.t("Select City")}
                </option>
                {cities.map((city: any) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Input>
              {errors.cityId && (
                <div className="text-danger">{errors.cityId}</div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label for="townshipId">{props.t("Township")}</Label>
              <Input
                type="select"
                id="townshipId"
                name="townshipId"
                value={townshipId}
                onChange={handleInputChange(setTownshipId, "townshipId")}
                disabled={!cityId}
                invalid={Boolean(errors.townshipId)}
              >
                <option value="0" hidden>
                  {props.t("Select Township")}
                </option>
                {townships.map((township: any) => (
                  <option key={township.id} value={township.id}>
                    {township.name}
                  </option>
                ))}
              </Input>
              {errors.townshipId && (
                <div className="text-danger">{errors.townshipId}</div>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="address">
                {props.t("Address")}
              </Label>
              <Input
                type="textarea"
                className="form-control"
                placeholder={props.t("Address")}
                id="address"
                name="address"
                rows={3}
                disabled={!isProviderSelected}
                value={address}
                onChange={handleInputChange(setAddress, "address")}
                invalid={!!errors.address}
              />
              {errors.address && <FormFeedback>{errors.address}</FormFeedback>}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="description">
                {props.t("Description")}
              </Label>
              <textarea
                className="form-control"
                placeholder={props.t("Description")}
                id="description"
                name="description"
                rows={3}
                disabled={!isProviderSelected}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </Col>
        </Row>
      </div>
      <div className="d-flex align-items-start gap-3 mt-4">
        <Button
          type="button"
          className="btn btn-success btn-label right ms-auto nexttab nexttab"
          onClick={handleSubmit}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          {props.t("Card Info")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  providers: state.PosProvider.providers,
  cities: state.Core.cities,
  townships: state.Core.townships,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchProviders: () => dispatch(getAllProviders()),
  fetchCities: () => dispatch(getAllCities()),
  fetchTownships: (cityId: number) => dispatch(getAllTownships(cityId)),
  t,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CustomerInfo);
