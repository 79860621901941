import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Button,
  CardBody,
  Card,
  FormFeedback,
  Spinner,
} from "reactstrap";
import logoLight from "../../assets/images/pospazar.png";
import { toast } from "react-toastify";
import { Trans, withTranslation } from "react-i18next";
import { postUploadFile } from "helpers/api/external_urls";
import {
  getAllCities,
  getAllCoreProviders,
  getAllTownships,
  sendMerchantApplication,
} from "slices/thunks";
import { connect, ConnectedProps } from "react-redux";
import { TFunction } from "i18next";
import Select from "react-select";
import {
  isEmail,
  isFileValid,
  isIBAN,
  isIdentityNumber,
  isPhoneNumber,
} from "helpers/data/validate";
import { AppEnv } from "AppEnv";
import { trimWhitespace } from "helpers/data/format";
import { RootState } from "slices";

enum MerchantType {
  individual = "individual",
  sole_proprietorship = "sole_proprietorship",
  corporate = "corporate",
}

const customStyles = {
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      backgroundColor: "#687cfe",
    };
  },
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    backgroundColor: "#687cfe",
    color: "white",
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: "white",
    backgroundColor: "#687cfe",
    ":hover": {
      backgroundColor: "#687cfe",
      color: "white",
    },
  }),
};

const RegistrationApplication = (props: PropsFromRedux & { t: TFunction }) => {
  const {
    cities,
    townships,
    coreProviders,
    fetchCities,
    fetchTownships,
    fetchProviders,
    sendApplication,
    applicationStatus,
    errorMessages,
    t,
  } = props;

  const [activeTab, setActiveTab] = useState(1);
  const [formData, setFormData] =
    useState<PosPazarTypes.MerchantApplicationBody>({
      name: "",
      merchant_type: MerchantType.individual,
      authorized_person_first_name: "",
      authorized_person_last_name: "",
      authorized_person_identification_number: "",
      authorized_person_email_address: "",
      authorized_person_phone_number: "",
      authorized_person_birth_date: "",
      tax_office: "",
      tax_number: "",
      iban: "",
      city: 0,
      township: 0,
      address: "",
      pos_providers: [],
      documents: [],
    });

  const [errors, setErrors] = useState<any>({});

  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    const heights = tabRefs.current.map((ref) => ref?.offsetHeight || 0);
    setMaxHeight(Math.max(...heights) + 10);
    fetchCities();
    fetchProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providers = useMemo(() => {
    return coreProviders.map((provider) => ({
      value: provider.key,
      label: provider.name,
    }));
  }, [coreProviders]);

  useEffect(() => {
    if (formData.city > 0) {
      fetchTownships(formData.city);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.city]);

  useEffect(() => {
    if (cities.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        city: cities[0].id,
      }));
    }
  }, [cities]);

  useEffect(() => {
    if (townships.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        township: townships[0].id,
      }));
    }
  }, [townships]);

  useEffect(() => {
    if (applicationStatus === "succeeded") {
      toast.success(t("Your application has been successfully received."), {
        onClose: () => {
          window.location.href = AppEnv.MailUrl;
        },
      });
    }
    if (applicationStatus === "failed") {
      toast.error(t("An error occurred while receiving your application."), {
        autoClose: 5000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationStatus]);

  const keysToProviderItems = useCallback(
    (keys: string[]) => {
      return keys
        .map((key) => {
          const provider = coreProviders.find((p) => p.key === key);
          return provider
            ? { value: provider.key, label: provider.name }
            : null;
        })
        .filter((item) => item !== null) as Array<{
        label: string;
        value: string;
      }>;
    },
    [coreProviders]
  );

  //#region Tab Validation
  const validateTabUser = useCallback(() => {
    const newErrors: any = {};
    if (!formData.authorized_person_first_name) {
      newErrors.authorized_person_first_name = "Field is required";
    }
    if (!formData.authorized_person_last_name) {
      newErrors.authorized_person_last_name = "Field is required";
    }
    const isIdentityNumberValid = isIdentityNumber(
      formData.authorized_person_identification_number
    );
    if (!isIdentityNumberValid.isValid) {
      newErrors.authorized_person_identification_number =
        isIdentityNumberValid.message;
    }
    const isEmailValid = isEmail(formData.authorized_person_email_address);
    if (!isEmailValid.isValid) {
      newErrors.authorized_person_email_address = isEmailValid.message;
    }
    const isPhoneNumberValid = isPhoneNumber(
      formData.authorized_person_phone_number
    );
    if (!isPhoneNumberValid.isValid) {
      newErrors.authorized_person_phone_number = isPhoneNumberValid.message;
    }
    if (!formData.authorized_person_birth_date) {
      newErrors.authorized_person_birth_date = "Field is required";
    }
    return newErrors;
  }, [formData]);

  const validateTabCompany = useCallback(() => {
    const newErrors: any = {};
    if (!formData.name) {
      newErrors.name = "Field is required";
    }
    if (formData.merchant_type !== MerchantType.individual) {
      if (!formData.tax_office) {
        newErrors.tax_office = "Field is required";
      }
      if (!formData.tax_number) {
        newErrors.tax_number = "Field is required";
      }
    }
    const isIbanValid = isIBAN(formData.iban, true);
    if (!isIbanValid.isValid) {
      newErrors.iban = isIbanValid.message;
    }
    return newErrors;
  }, [formData]);

  const validateTabAddress = useCallback(() => {
    const newErrors: any = {};
    if (formData.city === 0) {
      newErrors.city = "Field is required";
    }
    if (formData.township === 0) {
      newErrors.township = "Field is required";
    }
    if (!formData.address) {
      newErrors.address = "Field is required";
    }
    return newErrors;
  }, [formData]);

  const validateTabDocuments = useCallback(() => {
    const newErrors: any = {};
    [
      "tax_sheet",
      "signature_circular",
      "identity_card",
      "activity_certificate",
      "current_residence",
      "birth_place_document",
      "attendance_sheet",
    ]
      .filter((docType) => {
        return !formData.documents.some((doc) => doc.document_type === docType);
      })
      .forEach((docType) => {
        newErrors[docType] = "Field is required";
      });
    return newErrors;
  }, [formData]);

  const validateTabProduct = useCallback(() => {
    const newErrors: any = {};
    if (!formData.pos_providers.length) {
      newErrors.pos_providers = "Field is required";
    }
    return newErrors;
  }, [formData]);
  //#endregion Tab Validation

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateRegistrationStep = (tab: number) => {
    let newErrors: any = {};
    switch (tab) {
      case 1:
        newErrors = validateTabUser();
        break;
      case 2:
        newErrors = validateTabCompany();
        break;
      case 3:
        newErrors = validateTabAddress();
        break;
      case 4:
        newErrors = validateTabDocuments();
        break;
      case 5:
        newErrors = validateTabProduct();
        break;
      default:
        newErrors = {};
        break;
    }
    return newErrors;
  };

  const toggleTab = useCallback(
    (tab: number) => {
      if (activeTab < tab) {
        let _errors: any = {};
        Array.from({ length: tab - 1 }, (_, i) => i + 1).forEach((i) => {
          const newErrors = validateRegistrationStep(i);
          _errors = { ..._errors, ...newErrors };
        });
        setErrors(_errors);
        if (Object.keys(_errors).length === 0) {
          setActiveTab(tab);
        }
      } else if (activeTab > tab) {
        setActiveTab(tab);
      }
    },
    [activeTab, validateRegistrationStep]
  );

  const tabClass = useCallback(
    (tab: number) => {
      const classNames: string[] = [];
      if (activeTab === tab) {
        classNames.push("active");
      }
      if (activeTab > tab) {
        classNames.push("done");
      }
      return classNames.join(" ");
    },
    [activeTab]
  );

  const fileIsUploaded = useCallback(
    (docType: PosPazarTypes.MerhantApplicationDocumentType) => {
      if (formData.documents.length) {
        return formData.documents.some((doc) => doc.document_type === docType);
      }
      return false;
    },
    [formData]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (
    item: Array<{ label: string; value: string }>
  ) => {
    const selectedValues = item.map((i) => i.value);
    setFormData((prevData) => ({
      ...prevData,
      pos_providers: selectedValues,
    }));
  };

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    name: PosPazarTypes.MerhantApplicationDocumentType
  ) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      const fileValid = isFileValid(file);
      if (!fileValid.isValid) {
        toast.error(t(fileValid.message), {
          autoClose: 5000,
        });
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("doc_type", name);

      try {
        const response = await postUploadFile(formData);
        const document_url = response.data.file_url;

        setFormData((prevData) => {
          const updatedDocuments = [...prevData.documents];
          const index = updatedDocuments.findIndex(
            (doc) => doc.document_type === name
          );
          if (index !== -1) {
            updatedDocuments[index] = {
              document_url,
              document_type: name,
            };
          } else {
            updatedDocuments.push({
              document_url,
              document_type: name,
            });
          }
          return {
            ...prevData,
            documents: updatedDocuments,
          };
        });
      } catch (error) {
        toast.error(t("An error occurred while uploading the file."), {
          autoClose: 5000,
        });
      }
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.authorized_person_first_name) {
      newErrors.authorized_person_first_name = "Field is required";
    }
    if (!formData.authorized_person_last_name) {
      newErrors.authorized_person_last_name = "Field is required";
    }
    const isIdentityNumberValid = isIdentityNumber(
      formData.authorized_person_identification_number
    );
    if (!isIdentityNumberValid.isValid) {
      newErrors.authorized_person_identification_number =
        isIdentityNumberValid.message;
    }
    const isEmailValid = isEmail(formData.authorized_person_email_address);
    if (!isEmailValid.isValid) {
      newErrors.authorized_person_email_address = isEmailValid.message;
    }
    const isPhoneNumberValid = isPhoneNumber(
      formData.authorized_person_phone_number
    );
    if (!isPhoneNumberValid.isValid) {
      newErrors.authorized_person_phone_number = isPhoneNumberValid.message;
    }
    if (!formData.authorized_person_birth_date) {
      newErrors.authorized_person_birth_date = "Field is required";
    }
    if (!formData.name) {
      newErrors.name = "Field is required";
    }
    if (formData.merchant_type !== MerchantType.individual) {
      if (!formData.tax_office) {
        newErrors.tax_office = "Field is required";
      }
      if (!formData.tax_number) {
        newErrors.tax_number = "Field is required";
      }
    }
    const isIbanValid = isIBAN(formData.iban, true);
    if (!isIbanValid.isValid) {
      newErrors.iban = isIbanValid.message;
    }
    if (formData.city === 0) {
      newErrors.city = "Field is required";
    }
    if (formData.township === 0) {
      newErrors.township = "Field is required";
    }
    if (!formData.address) {
      newErrors.address = "Field is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (formData.merchant_type === MerchantType.individual) {
        formData.tax_office = "";
        formData.tax_number = "";
      }
      formData.iban = trimWhitespace(formData.iban);
      sendApplication(formData);
    } else {
      toast.error(t("Please fill out the form completely."), {
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
      <div className="bg-overlay"></div>
      <div className="auth-page-content overflow-hidden pt-lg-5">
        <Container className="justify-content-center">
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="70" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Card className="mt-4">
            <CardBody className="p-4">
              <form className="vertical-navs-step form-steps">
                <Row className="gy-5">
                  <Col lg={3}>
                    <Nav className="flex-column custom-nav nav-pills">
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={() => toggleTab(1)}
                          className={tabClass(1)}
                        >
                          <span className="step-title me-2">
                            <i className="ri-close-circle-fill step-icon me-2"></i>
                          </span>
                          <Trans i18nKey="User Information" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={() => toggleTab(2)}
                          className={tabClass(2)}
                        >
                          <span className="step-title me-2">
                            <i className="ri-close-circle-fill step-icon me-2"></i>
                          </span>
                          <Trans i18nKey="Company Information" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={() => toggleTab(3)}
                          className={tabClass(3)}
                        >
                          <span className="step-title me-2">
                            <i className="ri-close-circle-fill step-icon me-2"></i>
                          </span>
                          <Trans i18nKey="Address Information" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={() => toggleTab(4)}
                          className={tabClass(4)}
                        >
                          <span className="step-title me-2">
                            <i className="ri-close-circle-fill step-icon me-2"></i>
                          </span>
                          <Trans i18nKey="Documents" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={() => toggleTab(5)}
                          className={tabClass(5)}
                        >
                          <span className="step-title me-2">
                            <i className="ri-close-circle-fill step-icon me-2"></i>
                          </span>
                          <Trans i18nKey="Product Selection" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={() => toggleTab(6)}
                          className={tabClass(6)}
                        >
                          <span className="step-title me-2">
                            <i className="ri-close-circle-fill step-icon me-2"></i>
                          </span>
                          <Trans i18nKey="Complete Application" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <Col lg={9}>
                    <TabContent
                      activeTab={activeTab}
                      style={{ minHeight: maxHeight }}
                    >
                      <TabPane tabId={1}>
                        <div ref={(el) => (tabRefs.current[0] = el)}>
                          <FormGroup>
                            <Label for="firstName">
                              <Trans i18nKey="Name" />
                            </Label>
                            <Input
                              type="text"
                              id="firstName"
                              name="authorized_person_first_name"
                              placeholder={t("Name")}
                              value={formData.authorized_person_first_name}
                              onChange={handleInputChange}
                              invalid={!!errors.authorized_person_first_name}
                            />
                            {errors.authorized_person_first_name && (
                              <FormFeedback>
                                <Trans
                                  i18nKey={errors.authorized_person_first_name}
                                />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="lastName">
                              <Trans i18nKey="Surname" />
                            </Label>
                            <Input
                              type="text"
                              id="lastName"
                              placeholder={t("Surname")}
                              name="authorized_person_last_name"
                              value={formData.authorized_person_last_name}
                              onChange={handleInputChange}
                              invalid={!!errors.authorized_person_last_name}
                            />
                            {errors.authorized_person_last_name && (
                              <FormFeedback>
                                <Trans
                                  i18nKey={errors.authorized_person_last_name}
                                />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="identityNumber">
                              <Trans i18nKey="Identity Number" />
                            </Label>
                            <Input
                              type="text"
                              id="identityNumber"
                              placeholder={t("Identity Number")}
                              name="authorized_person_identification_number"
                              value={
                                formData.authorized_person_identification_number
                              }
                              onChange={handleInputChange}
                              invalid={
                                !!errors.authorized_person_identification_number
                              }
                            />
                            {errors.authorized_person_identification_number && (
                              <FormFeedback>
                                <Trans
                                  i18nKey={
                                    errors.authorized_person_identification_number
                                  }
                                />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="email">
                              <Trans i18nKey="Email" />
                            </Label>
                            <Input
                              type="email"
                              id="email"
                              placeholder={t("Email")}
                              name="authorized_person_email_address"
                              value={formData.authorized_person_email_address}
                              onChange={handleInputChange}
                              invalid={!!errors.authorized_person_email_address}
                            />
                            {errors.authorized_person_email_address && (
                              <FormFeedback>
                                <Trans
                                  i18nKey={
                                    errors.authorized_person_email_address
                                  }
                                />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="phoneNumber">
                              <Trans i18nKey="Phone Number" />
                            </Label>
                            <Input
                              type="tel"
                              id="phoneNumber"
                              placeholder={t("Phone Number")}
                              name="authorized_person_phone_number"
                              value={formData.authorized_person_phone_number}
                              onChange={handleInputChange}
                              invalid={!!errors.authorized_person_phone_number}
                            />
                            {errors.authorized_person_phone_number && (
                              <FormFeedback>
                                <Trans
                                  i18nKey={
                                    errors.authorized_person_phone_number
                                  }
                                />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="birthDate">
                              <Trans i18nKey="Birth Date" />
                            </Label>
                            <Input
                              type="date"
                              id="birthDate"
                              placeholder={t("Birth Date")}
                              name="authorized_person_birth_date"
                              value={formData.authorized_person_birth_date}
                              onChange={handleInputChange}
                              invalid={!!errors.authorized_person_birth_date}
                            />
                            {errors.authorized_person_birth_date && (
                              <FormFeedback>
                                <Trans
                                  i18nKey={errors.authorized_person_birth_date}
                                />
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </div>
                      </TabPane>
                      <TabPane tabId={2}>
                        <div ref={(el) => (tabRefs.current[1] = el)}>
                          <FormGroup>
                            <Label for="companyName">
                              <Trans i18nKey="Company Name" />
                            </Label>
                            <Input
                              type="text"
                              id="companyName"
                              placeholder={t("Company Name")}
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              invalid={!!errors.name}
                            />
                            {errors.name && (
                              <FormFeedback>
                                <Trans i18nKey={errors.name} />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="merchantType">
                              <Trans i18nKey="Company Type" />
                            </Label>
                            <Input
                              type="select"
                              id="merchantType"
                              name="merchant_type"
                              value={formData.merchant_type}
                              onChange={handleInputChange}
                              invalid={!!errors.merchant_type}
                            >
                              <option value={MerchantType.individual}>
                                <Trans i18nKey="Individual" />
                              </option>
                              <option value={MerchantType.sole_proprietorship}>
                                <Trans i18nKey="Sole Proprietorship" />
                              </option>
                              <option value={MerchantType.corporate}>
                                <Trans i18nKey="Corporate" />
                              </option>
                            </Input>
                            {errors.merchant_type && (
                              <FormFeedback>
                                <Trans i18nKey={errors.merchant_type} />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="taxNumber">
                              <Trans i18nKey="Tax Number" />
                            </Label>
                            <Input
                              type="text"
                              id="taxNumber"
                              placeholder={t("Tax Number")}
                              name="tax_number"
                              value={formData.tax_number}
                              onChange={handleInputChange}
                              disabled={
                                formData.merchant_type ===
                                MerchantType.individual
                              }
                              invalid={!!errors.tax_number}
                            />
                            {errors.tax_number && (
                              <FormFeedback>
                                <Trans i18nKey={errors.tax_number} />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="taxOffice">
                              <Trans i18nKey="Tax Office" />
                            </Label>
                            <Input
                              type="text"
                              id="taxOffice"
                              placeholder={t("Tax Office")}
                              name="tax_office"
                              value={formData.tax_office}
                              onChange={handleInputChange}
                              disabled={
                                formData.merchant_type ===
                                MerchantType.individual
                              }
                              invalid={!!errors.tax_office}
                            />
                            {errors.tax_office && (
                              <FormFeedback>
                                <Trans i18nKey={errors.tax_office} />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="iban">IBAN</Label>
                            <Input
                              type="text"
                              id="iban"
                              placeholder="IBAN"
                              name="iban"
                              value={formData.iban}
                              onChange={handleInputChange}
                              invalid={!!errors.iban}
                            />
                            {errors.iban && (
                              <FormFeedback>
                                <Trans i18nKey={errors.iban} />
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </div>
                      </TabPane>
                      <TabPane tabId={3}>
                        <div ref={(el) => (tabRefs.current[2] = el)}>
                          <FormGroup>
                            <Label for="city">{t("City")}</Label>
                            <Input
                              type="select"
                              id="city"
                              placeholder={t("City")}
                              name="city"
                              value={formData.city}
                              onChange={handleInputChange}
                              invalid={!!errors.city}
                            >
                              {" "}
                              <option value="0" hidden>
                                {t("Select City")}
                              </option>
                              {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              ))}
                            </Input>
                            {errors.city && (
                              <FormFeedback>
                                <Trans i18nKey={errors.city} />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="township">{t("Township")}</Label>
                            <Input
                              type="select"
                              id="township"
                              placeholder={t("Township")}
                              name="township"
                              value={formData.township}
                              onChange={handleInputChange}
                              invalid={!!errors.township}
                            >
                              {" "}
                              <option value="0" hidden>
                                {t("Select Township")}
                              </option>
                              {townships.map((township) => (
                                <option key={township.id} value={township.id}>
                                  {township.name}
                                </option>
                              ))}
                            </Input>
                            {errors.township && (
                              <FormFeedback>
                                <Trans i18nKey={errors.township} />
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="address">
                              <Trans i18nKey="Address" />
                            </Label>
                            <Input
                              type="textarea"
                              id="address"
                              placeholder={t("Address")}
                              name="address"
                              value={formData.address}
                              onChange={handleInputChange}
                              invalid={!!errors.address}
                            />
                            {errors.address && (
                              <FormFeedback>
                                <Trans i18nKey={errors.address} />
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </div>
                      </TabPane>
                      <TabPane tabId={4}>
                        <div ref={(el) => (tabRefs.current[3] = el)}>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="tax_sheet">
                                  <Trans i18nKey="Tax Sheet" />
                                </Label>
                                <Input
                                  type="file"
                                  id="tax_sheet"
                                  onChange={(e) =>
                                    handleFileChange(e, "tax_sheet")
                                  }
                                  valid={fileIsUploaded("tax_sheet")}
                                  invalid={
                                    !!errors.tax_sheet &&
                                    !fileIsUploaded("tax_sheet")
                                  }
                                />
                                {errors.tax_sheet && (
                                  <FormFeedback>
                                    <Trans i18nKey={errors.tax_sheet} />
                                  </FormFeedback>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Label for="signature_circular">
                                  <Trans i18nKey="Signature Circular" />
                                </Label>
                                <Input
                                  type="file"
                                  id="signature_circular"
                                  onChange={(e) =>
                                    handleFileChange(e, "signature_circular")
                                  }
                                  valid={fileIsUploaded("signature_circular")}
                                  invalid={
                                    !!errors.signature_circular &&
                                    !fileIsUploaded("signature_circular")
                                  }
                                />
                                {errors.signature_circular && (
                                  <FormFeedback>
                                    <Trans
                                      i18nKey={errors.signature_circular}
                                    />
                                  </FormFeedback>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Label for="identity_card">
                                  <Trans i18nKey="Identity Card" />
                                </Label>
                                <Input
                                  type="file"
                                  id="identity_card"
                                  onChange={(e) =>
                                    handleFileChange(e, "identity_card")
                                  }
                                  valid={fileIsUploaded("identity_card")}
                                  invalid={
                                    !!errors.identity_card &&
                                    !fileIsUploaded("identity_card")
                                  }
                                />
                                {errors.identity_card && (
                                  <FormFeedback>
                                    <Trans i18nKey={errors.identity_card} />
                                  </FormFeedback>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Label for="activity_certificate">
                                  <Trans i18nKey="Activity Certificate" />
                                </Label>
                                <Input
                                  type="file"
                                  id="activity_certificate"
                                  onChange={(e) =>
                                    handleFileChange(e, "activity_certificate")
                                  }
                                  valid={fileIsUploaded("activity_certificate")}
                                  invalid={
                                    !!errors.activity_certificate &&
                                    !fileIsUploaded("activity_certificate")
                                  }
                                />
                                {errors.activity_certificate && (
                                  <FormFeedback>
                                    <Trans
                                      i18nKey={errors.activity_certificate}
                                    />
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="current_residence">
                                  <Trans i18nKey="Current Residence" />
                                </Label>
                                <Input
                                  type="file"
                                  id="current_residence"
                                  onChange={(e) =>
                                    handleFileChange(e, "current_residence")
                                  }
                                  valid={fileIsUploaded("current_residence")}
                                  invalid={
                                    !!errors.current_residence &&
                                    !fileIsUploaded("current_residence")
                                  }
                                />
                                {errors.current_residence && (
                                  <FormFeedback>
                                    <Trans i18nKey={errors.current_residence} />
                                  </FormFeedback>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Label for="birth_place_document">
                                  <Trans i18nKey="Official Document Showing Place of Birth" />
                                </Label>
                                <Input
                                  type="file"
                                  id="birth_place_document"
                                  onChange={(e) =>
                                    handleFileChange(e, "birth_place_document")
                                  }
                                  valid={fileIsUploaded("birth_place_document")}
                                  invalid={
                                    !!errors.birth_place_document &&
                                    !fileIsUploaded("birth_place_document")
                                  }
                                />
                                {errors.birth_place_document && (
                                  <FormFeedback>
                                    <Trans
                                      i18nKey={errors.birth_place_document}
                                    />
                                  </FormFeedback>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Label for="attendance_sheet">
                                  <Trans i18nKey="Attendance Sheet" />
                                </Label>
                                <Input
                                  type="file"
                                  id="attendance_sheet"
                                  onChange={(e) =>
                                    handleFileChange(e, "attendance_sheet")
                                  }
                                  valid={fileIsUploaded("attendance_sheet")}
                                  invalid={
                                    !!errors.attendance_sheet &&
                                    !fileIsUploaded("attendance_sheet")
                                  }
                                />
                                {errors.attendance_sheet && (
                                  <FormFeedback>
                                    <Trans i18nKey={errors.attendance_sheet} />
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                      <TabPane tabId={5}>
                        <FormGroup>
                          <Label for="posProviders">
                            <Trans i18nKey="Product Selection" />
                          </Label>
                          <Select
                            value={keysToProviderItems(formData.pos_providers)}
                            options={providers}
                            isMulti={true}
                            onChange={handleSelectChange}
                            styles={customStyles}
                          />
                          {errors.pos_providers && (
                            <FormFeedback>
                              <Trans i18nKey={errors.pos_providers} />
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </TabPane>
                      <TabPane tabId={6}>
                        <div ref={(el) => (tabRefs.current[4] = el)}>
                          {applicationStatus === "loading" ? (
                            <Spinner color="primary"> Loading... </Spinner>
                          ) : (
                            <Button
                              color="primary"
                              onClick={handleSubmit}
                              disabled={applicationStatus === "succeeded"}
                            >
                              {t("Complete Application")}
                            </Button>
                          )}
                          {applicationStatus === "failed" &&
                            errorMessages.length > 0 && (
                              <div className="mt-3">
                                {errorMessages.map((message, index) => (
                                  <div key={index} className="text-danger">
                                    {index + 1}. {message}
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-between mt-4">
                      <Button
                        color="secondary"
                        onClick={() => toggleTab(activeTab - 1)}
                        disabled={activeTab === 1}
                      >
                        <Trans i18nKey="Previous" />
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => toggleTab(activeTab + 1)}
                        disabled={activeTab === 6}
                      >
                        <Trans i18nKey="Next" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  coreProviders: state.Core.coreProviders,
  cities: state.Core.cities,
  townships: state.Core.townships,
  applicationStatus: state.Core.applicationStatus,
  errorMessages: state.Core.errorMessages,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchProviders: () => dispatch(getAllCoreProviders()),
  fetchCities: () => dispatch(getAllCities()),
  fetchTownships: (cityId: number) => dispatch(getAllTownships(cityId)),
  sendApplication: (body: PosPazarTypes.MerchantApplicationBody) =>
    dispatch(sendMerchantApplication(body)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(RegistrationApplication));
