import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDealers, patchActiveDealerReq } from "helpers/api/inner_urls";

export const fetchDealers = createAsyncThunk(
  "dealers/fetchDealers",
  async () => {
    const { data } = await getDealers();
    return data;
  }
);

export const patchActiveDealer = createAsyncThunk(
  "dealers/changeActiveDealer",
  async (dealerId: number) => {
    await patchActiveDealerReq({ merchant_id: dealerId });
  }
);
