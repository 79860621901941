import React, { useState } from "react";
import { Trans } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Table,
  Tooltip,
} from "reactstrap";

interface BasicTableProps {
  title?: string;
  cardColor?: string;
  columns: PosPazarTypes.CustomTableColumn[];
  data: any[];
  headerColor?: string;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  status?: PosPazarTypes.ApiRequestStatus;
  errorTooltip?: string;
}
const BasicTable = (props: BasicTableProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Card
        className={`table-responsive card-height-100${
          props.cardColor ? " border border-" + props.cardColor : ""
        } ${props.className}`}
      >
        <CardHeader
          className={`d-flex justify-content-between align-items-center ${
            props.cardColor ? "border bg-" + props.cardColor : ""
          }`}
        >
          <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
            {props.title}
          </p>
          {props.status === "loading" && <Spinner />}
          {props.status === "failed" && (
            <div>
              <i
                id="CustomTableErrorTooltip"
                className="ri-error-warning-fill text-danger"
                color="red"
              />
              <Tooltip
                isOpen={tooltipOpen}
                target="CustomTableErrorTooltip"
                toggle={toggleTooltip}
              >
                <Trans
                  i18nKey={props.errorTooltip ?? "Unknown error occurred"}
                />
              </Tooltip>
            </div>
          )}
        </CardHeader>
        <CardBody>
          <Table className={`align-middle table-nowrap ${props.className}`}>
            <thead
              className={`thead-${props.headerColor} ${props.headerClassName}`}
            >
              <tr>
                {props.columns.map((column, index) => (
                  <th key={index} scope="col">
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={props.bodyClassName}>
              {props.data.map((row, index) => (
                <tr key={index}>
                  {props.columns.map((column, index) => (
                    <td key={index}>
                      {column.cell ? column.cell(row) : row[column.accessor]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default BasicTable;
