import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPosProviders, post3DPayment } from "helpers/api/inner_urls";

export const getAllProviders = createAsyncThunk(
  "posprovider/getAllProviders",
  async () => {
    const { data } = await getPosProviders();
    return data;
  }
);

export const new3dPayment = createAsyncThunk(
  "posprovider/new3dPayment",
  async (payload: any) => {
    const { provider, ...body } = payload;
    const { data } = await post3DPayment(provider, body);
    if (data) {
      setTimeout(() => {
        window.document.open();
        window.document.write(String(data));
        window.document.close();
      }, 3000);
    }
  }
);
