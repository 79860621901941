import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrenciesRatesReq } from "helpers/api/external_urls";
import { getDashboardData, getMe } from "helpers/api/inner_urls";
import { normalizeCurrencyData } from "helpers/data/customCurrencyNormalization";

export const getUserInfo = createAsyncThunk(
  "dashboard/getUserInfo",
  async () => {
    const { data } = await getMe();
    return data;
  }
);

export const getDashboardInfo = createAsyncThunk(
  "dashboard/getDashboardInfo",
  async () => {
    const { data } = await getDashboardData();
    return data;
  }
);

export const getCurrenciesRates = createAsyncThunk(
  "dashboard/getCurrenciesRates",
  async () => {
    const { data } = await getCurrenciesRatesReq();
    return normalizeCurrencyData(data);
  }
);
