import React, { useCallback, useEffect, useMemo } from "react";
import { Badge, Col, Container, Row } from "reactstrap";
import DashboardWidget from "pages/Dashboard/DashboardWidget";
import BasicTable from "Components/BasicTable";
import { RootState } from "slices";
import { connect, ConnectedProps } from "react-redux";
import { getCurrenciesRates, getDashboardInfo } from "slices/thunks";
import { t } from "i18next";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";

const DashboardEcommerce = (props: PropsFromRedux) => {
  const {
    dashboardInfo,
    dashboardStatus,
    userInfo,
    currencyRates,
    currencyRatesStatus,
    error,
    fetchDashboardInfo,
    fetchCurrenciesRates,
    t,
  } = props;
  document.title = "PosPazar";

  useEffect(() => {
    fetchDashboardInfo();
  }, [fetchDashboardInfo]);

  useEffect(() => {
    fetchCurrenciesRates();
  }, [fetchCurrenciesRates]);

  useEffect(() => {
    if (error) {
      toast.error(error, { autoClose: 5000 });
    }
  }, [error]);

  const lastTransactionsColumns = useMemo<PosPazarTypes.CustomTableColumn[]>(
    () => [
      {
        header: t("Total Amount"),
        accessor: "total_amount",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          return row.total_amount + " ₺";
        },
      },
      {
        header: t("Installment"),
        accessor: "installment",
      },
      {
        header: t("Created At"),
        accessor: "created_at",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          const date = new Date(row.created_at);
          return date.toLocaleDateString("tr-TR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
        },
      },
      {
        header: t("Status"),
        accessor: "status",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          const status = row.status;
          const color =
            status === "approved"
              ? "success"
              : status === "pending"
              ? "warning"
              : "danger";
          return (
            <Badge color={color} pill={true}>
              <Trans i18nKey={status} />
            </Badge>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const currencyColumns = useMemo<PosPazarTypes.CustomTableColumn[]>(
    () => [
      {
        header: t("Exchange"),
        accessor: "code",
      },
      {
        header: t("Buying"),
        accessor: "buying",
      },
      {
        header: t("Selling"),
        accessor: "selling",
      },
    ],
    [t]
  );

  const getGreetingMessage = useCallback(() => {
    const first_name = userInfo?.first_name;
    const currentHour = new Date().getHours();
    let greeting = t("Good Evening");

    if (currentHour > 3 && currentHour < 11) {
      greeting = t("Good Morning");
    } else if (currentHour > 10 && currentHour < 18) {
      greeting = t("Good Afternoon");
    }

    return `${greeting}, ${first_name}!`;
  }, [t, userInfo]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <div className="flex-grow-1 mb-1">
                <h4 className="fs-16 mb-1">{getGreetingMessage()}</h4>
                <p className="text-muted mb-0">
                  <Trans i18nKey="Here's what's happening with your dealer today" />
                </p>
              </div>
            </Col>
            <Col md="3">
              <DashboardWidget
                value={dashboardInfo?.transaction_statistics.daily}
                status={dashboardStatus}
                cardColor="primary-subtle"
                label={t("Daily Transaction Amount")}
              />
            </Col>
            <Col md="3">
              <DashboardWidget
                value={dashboardInfo?.transaction_statistics.monthly}
                status={dashboardStatus}
                cardColor="success-subtle"
                label={t("Monthly Transaction Amount")}
              />
            </Col>
            <Col md="3">
              <DashboardWidget
                value={dashboardInfo?.transaction_statistics.yearly}
                status={dashboardStatus}
                cardColor="secondary-subtle"
                label={t("Yearly Transaction Amount")}
              />
            </Col>
            <Col md="3">
              <DashboardWidget
                value={dashboardInfo?.transaction_statistics.total}
                status={dashboardStatus}
                cardColor="info-subtle"
                label={t("Total Transaction Amount")}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3" style={{ maxHeight: "55vh" }}>
              <BasicTable
                title={t("Exchange Rates")}
                cardColor="primary-subtle"
                columns={currencyColumns}
                data={currencyRates}
                status={currencyRatesStatus}
                errorTooltip={t("An error occurred while loading the table.")}
              />
            </Col>
            <Col md="9" style={{ maxHeight: "55vh" }}>
              <BasicTable
                title={t("Last Transactions")}
                cardColor="warning-subtle"
                columns={lastTransactionsColumns}
                data={dashboardInfo?.last_transactions ?? []}
                status={dashboardStatus}
                errorTooltip={t("An error occurred while loading the table.")}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  dashboardInfo: state.Dashboard.dashboardInfo,
  dashboardStatus: state.Dashboard.dashboardStatus,
  userInfo: state.Dashboard.userInfo,
  error: state.Dashboard.error,
  currencyRates: state.Dashboard.currencyRates,
  currencyRatesStatus: state.Dashboard.currencyRatesStatus,
  currencyRatesError: state.Dashboard.currencyRatesError,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchDashboardInfo: () => dispatch(getDashboardInfo()),
  fetchCurrenciesRates: () => dispatch(getCurrenciesRates()),
  t,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DashboardEcommerce);
