import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { RootState } from "slices";
import { patchActiveDealer, fetchDealers } from "slices/thunks";

const DealerDropdown = (props: PropsFromRedux) => {
  const { dealers, changeActiveDealer, fetchDealers } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [activeDealer, setActiveDealer] = useState<
    PosPazarTypes.DealerList | undefined
  >(undefined);

  useEffect(() => {
    fetchDealers();
  }, [fetchDealers]);

  useEffect(() => {
    if (Array.isArray(dealers)) {
      const _activeDealer = dealers.find((d) => d.is_active_merchant);
      setActiveDealer(_activeDealer);
    }
  }, [dealers]);

  function handleActiveDealerChange(dealer: PosPazarTypes.DealerList) {
    if (dealer.id !== activeDealer?.id) {
      setActiveDealer(dealer);
      changeActiveDealer(dealer.id);
    }
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="outlined">
        {activeDealer ? activeDealer.name : "Bayi Seçin"}
      </DropdownToggle>
      <DropdownMenu>
        {dealers.map((dealer: PosPazarTypes.DealerList) => {
          return (
            <DropdownItem
              key={dealer.id}
              active={dealer.id === activeDealer?.id}
              style={{
                fontWeight: dealer.is_main_merchant ? "bold" : "normal",
                color: dealer.id === activeDealer?.id ? "blue" : "black",
              }}
              onClick={() => {
                handleActiveDealerChange(dealer);
              }}
            >
              {dealer.name} {dealer.is_main_merchant && "(Ana Bayi)"}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  dealers: state.Dealer.dealerList,
});

const mapDispatchToProps = (dispact: any) => ({
  changeActiveDealer: (dealerId: number) =>
    dispact(patchActiveDealer(dealerId)),
  fetchDealers: () => dispact(fetchDealers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DealerDropdown);
