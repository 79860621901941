import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInstallments } from "helpers/api/inner_urls";

export const getCreditCardInstallmentOptions = createAsyncThunk(
  "creditcard/getCreditCardInstallmentOptions",
  async (state: { provider: string; amount: number; binNumber: string }) => {
    const { provider, amount, binNumber } = state;
    const { data } = await getInstallments(provider, amount, binNumber);
    return data;
  }
);
