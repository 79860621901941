import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { closeDetailModal } from "slices/transaction/transactionReducer";

const TransactionModal = (props: {
  transaction: PosPazarTypes.TransactionDetail | null;
  detailModalShow: boolean;
  onClose: () => void;
}) => {
  const { transaction, detailModalShow, onClose } = props;
  if (!transaction) {
    return <></>;
  }

  const getStatus = (status: string) => {
    const color =
      status === "approved"
        ? "success"
        : status === "pending"
        ? "warning"
        : "danger";
    return (
      <Badge color={color} pill={true}>
        <Trans i18nKey={status} />
      </Badge>
    );
  };

  return (
    <Modal isOpen={detailModalShow} toggle={() => onClose()} centered>
      <ModalHeader
        className="modal-title"
        id="myModalLabel"
        toggle={() => {
          onClose();
        }}
      >
        <Trans i18nKey="Transaction Detail" />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6">
            <strong>
              <Trans i18nKey="Amount" /> :
            </strong>{" "}
            {transaction.amount}
          </Col>
          <Col md="6">
            <strong>
              <Trans i18nKey="Total Amount" /> :
            </strong>{" "}
            {transaction.total_amount}
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <strong>
              <Trans i18nKey="Installment" /> :
            </strong>{" "}
            {transaction.installment}
          </Col>
          <Col md="6">
            <strong>
              {" "}
              <Trans i18nKey="Status" /> :
            </strong>{" "}
            {getStatus(transaction.status)}
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <strong>
              <Trans i18nKey="Created At" />:
            </strong>{" "}
            {new Date(transaction.created_at).toLocaleString()}
          </Col>
          <Col md="6">
            <strong>
              <Trans i18nKey="Updated At" />:
            </strong>{" "}
            {new Date(transaction.updated_at).toLocaleString()}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  transaction: state.Transaction.transaction,
  detailModalShow: state.Transaction.detailModalShow,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClose: () => dispatch(closeDetailModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionModal);
