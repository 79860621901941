import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";

interface Dealer {
  value: number;
  label: string;
}
const dealers: Array<Dealer> = [
  { value: 1, label: "Dealer 1" },
  { value: 2, label: "Dealer 2" },
  { value: 3, label: "Dealer 3" },
];
const customStyles = {
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      backgroundColor: "#687cfe",
    };
  },
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    backgroundColor: "#687cfe",
    color: "white",
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: "white",
    backgroundColor: "#687cfe",
    ":hover": {
      backgroundColor: "#687cfe",
      color: "white",
    },
  }),
};
const DealerUserModal = (props: {
  isOpen: boolean;
  toggle: () => void;
  dealerUser: any;
}) => {
  const { isOpen, toggle, dealerUser } = props;
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>(""); // New state for phone number
  const [selectDealers, setSelectDealers] = useState<Dealer[]>([]);

  useEffect(() => {
    if (dealerUser) {
      setFirstName(dealerUser.firstName);
      setLastName(dealerUser.lastName);
      setEmail(dealerUser.email);
      setPhoneNumber(dealerUser.phoneNumber); // Set phone number
      setSelectDealers(
        dealerUser.dealerIds.map((dealerId: number) => {
          return dealers.find((dealer) => dealer.value === dealerId);
        })
      );
    }
  }, [dealerUser]);

  function selectValueDealer(val: any) {
    console.log(val);
    setSelectDealers(val);
  }

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()}>
      <ModalHeader>Dealer User</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstName">Adı</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Soyadı</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="phoneNumber">Telefon Numarası</Label>
            <Input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="dealerIds">Yetkili Bayiler</Label>
            <Select
              value={selectDealers}
              isMulti={true}
              onChange={selectValueDealer}
              options={dealers}
              styles={customStyles}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button content="Save" icon="checkmark" onClick={() => toggle()}>
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DealerUserModal;
