import TableContainer from "Components/Common/TableContainer";
import { useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import DealerUserModal from "./DealerUserModal";

interface Branch {
  id: number;
  name: string;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dealerIds: number[];
  branches: Branch[];
  isMainBranch: boolean;
}

const initialUsers: User[] = [
  {
    id: 1,
    firstName: "User",
    lastName: "One",
    email: "user1@example.com",
    phoneNumber: "123-456-7890",
    dealerIds: [1, 2],
    branches: [
      { id: 1, name: "Branch 1" },
      { id: 2, name: "Branch 2" },
    ],
    isMainBranch: true,
  },
  {
    id: 2,
    firstName: "User",
    lastName: "Two",
    email: "user2@example.com",
    phoneNumber: "098-765-4321",
    dealerIds: [2, 3],
    branches: [
      { id: 2, name: "Branch 2" },
      { id: 3, name: "Branch 3" },
    ],
    isMainBranch: false,
  },
];

const DealerUserList = () => {
  const users = initialUsers;
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  const handleDetailClick = (user: User) => {
    setSelectedUser(user);
    setIsDetailModalOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        header: "Ad Soyad",
        accessorKey: "fullName",
        cell: (cell: any) => (
          <span className="fw-semibold">
            {cell.row.original.firstName} {cell.row.original.lastName}
          </span>
        ),
      },
      {
        header: "E-posta",
        accessorKey: "email",
      },
      {
        header: "Ana Bayi",
        accessorKey: "isMainBranch",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell.getValue() ? "Evet" : "Hayır"}</span>,
      },
      {
        header: "Yetkili Olduğu Bayi Sayısı",
        accessorKey: "branchCount",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell.row?.original?.branches.length}</span>,
      },
      {
        header: "İşlemler",
        accessorKey: "actions",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="d-flex justify-content-center gap-3">
            <Button
              className="btn btn-primary"
              onClick={() => handleDetailClick(cell.row.original)}
            >
              Detay
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="card-height-100">
                <CardHeader>Kullanıcı Listesi</CardHeader>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    customPageSize={5}
                    isGlobalFilter={true}
                    SearchPlaceholder="Ara..."
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {selectedUser && (
        <DealerUserModal
          isOpen={isDetailModalOpen}
          toggle={() => setIsDetailModalOpen(!isDetailModalOpen)}
          dealerUser={selectedUser}
        />
      )}
    </>
  );
};

export default DealerUserList;
