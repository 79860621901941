import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

const RefundModal = ({
  isOpen,
  toggle,
}: {
  isOpen: boolean;
  toggle: () => void;
  refundData: any;
}) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()}>
      <ModalHeader
        className="modal-title"
        id="myModalLabel"
        toggle={() => {
          toggle();
        }}
      >
        İade Talebi
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>Bu sayfa geliştirilecektir</Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default RefundModal;
