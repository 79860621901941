import { createSlice } from "@reduxjs/toolkit";
import { getInstallmentOptions } from "./installmentThunk";

export const initialState = {
  installments: [],
  error: "",
  loading: false,
  errorMsg: false,
};

const installmentSlice = createSlice({
  name: "installment",
  initialState,
  reducers: {
    changeInstallmentLoading(
      state: { loading: boolean },
      action: { payload: boolean }
    ) {
      state.loading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      getInstallmentOptions.fulfilled,
      (state: any, action: any) => {
        state.installments = action.payload;
        state.loading = false;
        state.errorMsg = false;
      }
    );
    builder.addCase(
      getInstallmentOptions.rejected,
      (state: any, action: any) => {
        state.installments = [];
        state.loading = false;
        state.errorMsg = true;
      }
    );
  },
});

export const { changeInstallmentLoading } = installmentSlice.actions;
export default installmentSlice.reducer;
