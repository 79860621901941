import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/pospazar.png";
import CreditCard from "Components/CreditCard";
import {
  getAllCities,
  getAllTownships,
  getOnePaymentLinkRequest,
  postLinkPaymentRequest,
} from "slices/thunks";
import { t } from "i18next";
import { RootState } from "slices";

const PaymentRequest: React.FC<PropsFromRedux> = (props) => {
  const {
    paymentLink,
    cities,
    townships,
    fetchAllCities,
    fetchAllTownships,
    fetchOnePaymentLinkRequest,
    paymentLinkStatus,
    paymentRequestStatus,
    t,
    updLinkPaymentRequest,
  } = props;
  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const [form, setForm] = useState<PosPazarTypes.LinkPaymentPayload>({
    card_holder_name: "",
    card_no: "",
    expire_date: "",
    cvv: "",
    city_id: 0,
    township_id: 0,
    installment: 0,
  });
  const [cardIsValid, setCardIsValid] = useState(false);

  useEffect(() => {
    fetchAllCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (form.city_id) {
      fetchAllTownships(form.city_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.city_id]);

  useEffect(() => {
    if (paymentLink && Object.keys(paymentLink).length > 0) {
      setForm({
        ...form,
        city_id: paymentLink.city,
        township_id: paymentLink.township,
        installment: paymentLink.minimum_installment,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLink]);

  useEffect(() => {
    if (!uuid || uuid.length !== 36) {
      toast.error(t("Invalid or expired payment page."), {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } else {
      fetchOnePaymentLinkRequest(uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, navigate, fetchOnePaymentLinkRequest]);

  useEffect(() => {
    if (paymentLinkStatus === "failed") {
      toast.error(t("Invalid or expired payment page."), {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLinkStatus]);

  useEffect(() => {
    switch (paymentRequestStatus) {
      case "loading":
        toast.info(t("Payment request sent, please wait..."), {
          autoClose: 5000,
        });
        break;
      case "failed":
        toast.error(t("Payment failed."), {
          autoClose: 5000,
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequestStatus]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!uuid) {
      return;
    }
    if (!cardIsValid) {
      toast.error("Lütfen geçerli bir kart bilgisi giriniz", {
        autoClose: 5000,
      });
      return;
    }
    updLinkPaymentRequest({ uuid, body: form });
  };

  const onChangeCreditCard = (e: PosPazarTypes.CardInfoProps) => {
    setForm({
      ...form,
      card_holder_name: e.cardHolder,
      card_no: e.cardNumber,
      expire_date: e.expiry,
      cvv: e.cvc,
    });
  };

  const renderInstallmentOptions = () => {
    const options = [];
    if (paymentLink?.minimum_installment && paymentLink?.maximum_installment) {
      for (
        let i = paymentLink.minimum_installment;
        i <= paymentLink.maximum_installment;
        i++
      ) {
        options.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    }
    return options;
  };

  return (
    <Container className="auth-page-content mt-lg-5">
      <Row className="justify-content-center">
        <Col md={12} className="text-center w-75">
          <Link to="/">
            <img src={logo} alt="Logo" className="mb-4 w-50" />
          </Link>
        </Col>
        <Col md={8}>
          <Form onSubmit={handleSubmit} className="wider-form">
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formTitle">{t("Title")}</Label>
                  <Input
                    type="text"
                    name="title"
                    id="formTitle"
                    value={paymentLink?.title || ""}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formAmount">{t("Amount")}</Label>
                  <Input
                    type="text"
                    name="amount"
                    id="formAmount"
                    value={paymentLink?.amount + " ₺"}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formCityId">{t("City")}</Label>
                  <Input
                    type="select"
                    id="city_id"
                    value={form.city_id}
                    onChange={handleChange}
                    // invalid={Boolean(errors.cityId)}
                  >
                    <option value="0" hidden>
                      {t("Select City")}
                    </option>
                    {cities.map((city: any) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formTownshipId">{t("Township")}</Label>
                  <Input
                    type="select"
                    id="townshipId"
                    value={form.township_id}
                    onChange={handleChange}
                    disabled={!form.city_id}
                  >
                    <option value="0" hidden>
                      {t("Select Township")}
                    </option>
                    {townships.map((township: any) => (
                      <option key={township.id} value={township.id}>
                        {township.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formInstallment">{t("Installment")}</Label>
                  <Input
                    type="select"
                    name="installment"
                    id="formInstallment"
                    value={form.installment}
                    onChange={handleChange}
                  >
                    {renderInstallmentOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <CreditCard
                value={{
                  cardHolder: form.card_holder_name,
                  cardNumber: form.card_no,
                  expiry: form.expire_date,
                  cvc: form.cvv,
                }}
                onChange={onChangeCreditCard}
                isValid={setCardIsValid}
                {...props}
              />
            </FormGroup>
            <Button
              color="primary"
              type="submit"
              className="w-100 mt-3"
              disabled={
                !(
                  paymentLinkStatus === "succeeded" &&
                  paymentRequestStatus === "idle"
                )
              }
            >
              {t("Pay")}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  paymentLink: state.PaymentRequest.paymentLink,
  paymentLinkStatus: state.PaymentRequest.paymentLinkStatus,
  paymentRequestStatus: state.PaymentRequest.paymentRequestStatus,
  cities: state.Core.cities,
  townships: state.Core.townships,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchOnePaymentLinkRequest: (uuid: string) =>
    dispatch(getOnePaymentLinkRequest(uuid)),
  fetchAllCities: () => dispatch(getAllCities()),
  fetchAllTownships: (cityId: number) => dispatch(getAllTownships(cityId)),
  updLinkPaymentRequest: (paylod: {
    uuid: string;
    body: PosPazarTypes.LinkPaymentPayload;
  }) => dispatch(postLinkPaymentRequest(paylod)),
  t,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PaymentRequest);
