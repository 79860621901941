import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutThunk } from "../../slices/thunks";
import { RootState } from "slices";

const Logout: React.FC = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const isUserLogout = useSelector(
    (state: RootState) => state.Auth.isUserLogout
  );

  useEffect(() => {
    dispatch(logoutThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isUserLogout) {
      navigate("/login");
    } else {
      navigate(-1);
    }
  }, [isUserLogout, navigate]);

  return null;
};

export default Logout;
