import { createSlice } from "@reduxjs/toolkit";
import {
  getOnePaymentLinkRequest,
  postLinkPaymentRequest,
} from "./paymentRequestThunk";

type StateType = {
  paymentLink: PosPazarTypes.LinkPayment | undefined;
  paymentLinkStatus: PosPazarTypes.ApiRequestStatus;
  paymentRequestStatus: PosPazarTypes.ApiRequestStatus;
  error: string;
};
export const initialState: StateType = {
  paymentLink: undefined,
  paymentLinkStatus: "idle",
  paymentRequestStatus: "idle",
  error: "",
};

const paymentRequestSlice = createSlice({
  name: "paymentRequests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOnePaymentLinkRequest.pending, (state) => {
        state.paymentLinkStatus = "loading";
      })
      .addCase(getOnePaymentLinkRequest.fulfilled, (state, action) => {
        state.paymentLink = action.payload;
        state.paymentLinkStatus = "succeeded";
      })
      .addCase(getOnePaymentLinkRequest.rejected, (state, action: any) => {
        state.error = action.payload;
        state.paymentLinkStatus = "failed";
      });
    builder
      .addCase(postLinkPaymentRequest.pending, (state) => {
        state.paymentRequestStatus = "loading";
      })
      .addCase(postLinkPaymentRequest.fulfilled, (state) => {
        state.paymentRequestStatus = "succeeded";
      })
      .addCase(postLinkPaymentRequest.rejected, (state, action) => {
        // state.error = action.payload.error.message;
        state.paymentRequestStatus = "failed";
      });
  },
});

// export const {} = paymentRequestSlice.actions;
export default paymentRequestSlice.reducer;
