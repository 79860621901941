import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInstallments } from "helpers/api/inner_urls";

export const getInstallmentOptions = createAsyncThunk(
  "installment/getInstallmentOptions",
  async (state: { provider: string; amount: number; cardNumber: string }) => {
    const { provider, amount, cardNumber } = state;
    const { data } = await getInstallments(provider, amount, cardNumber);
    return data;
  }
);
