import React from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap";

const MainDealer: React.FC<any> = (props: { dealer: any }) => {
  const { dealer } = props;

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Ana Bayi Bilgileri</CardTitle>
        <Row className="">
          <Col md="12">
            <Form>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="name">İsim</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={dealer.name ?? ""}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="address">Adres</Label>
                    <Input
                      type="text"
                      name="address"
                      id="address"
                      value={dealer.address ?? ""}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="phone">Telefon</Label>
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      value={dealer.phoneNumber ?? ""}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={dealer.email ?? ""}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="taxOffice">Vergi Dairesi</Label>
                    <Input
                      type="text"
                      name="taxOffice"
                      id="taxOffice"
                      value={dealer.taxOffice ?? ""}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="taxNumber">Vergi Numarası</Label>
                    <Input
                      type="text"
                      name="taxNumber"
                      id="taxNumber"
                      value={dealer.taxNumber ?? ""}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default MainDealer;
