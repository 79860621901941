import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthorization } from "helpers/api/inner_api";
import { login, setBlackList } from "helpers/api/inner_urls";
import { resetAuthState } from "./authReducer";

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (user: PosPazarTypes.LoginPaylod) => {
    const { data } = await login({
      username: user.username,
      password: user.password,
    });
    setAuthorization(data.access);
    localStorage.setItem("authUser", JSON.stringify(data));
  }
);

export const logoutThunk = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    dispatch(resetAuthState());
    const tokens = localStorage.getItem("authUser");
    const { refresh } = tokens ? JSON.parse(tokens) : null;
    localStorage.removeItem("authUser");
    if (refresh) {
      await setBlackList({ refresh });
    }
  }
);
