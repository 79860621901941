import flagus from "../assets/images/flags/us.svg";
import flagturkey from "../assets/images/flags/tr.svg";

const languages = {
  en: {
    label: "English",
    flag: flagus,
  },
  tr: {
    label: "Türkçe",
    flag: flagturkey,
  },
};

export default languages;
