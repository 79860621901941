import { createSlice } from "@reduxjs/toolkit";
import { getAllProviders, new3dPayment } from "./posproviderThunk";

type StateType = {
  providers: Array<PosPazarTypes.PosProvider>;
  paymentStatus: PosPazarTypes.ApiRequestStatus;
  error: string | undefined;
  loading: boolean;
  errorMsg: boolean;
};
export const initialState: StateType = {
  providers: [],
  paymentStatus: "idle",
  error: undefined,
  loading: false,
  errorMsg: false,
};

const paymentLinkSlice = createSlice({
  name: "posprovider",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllProviders.fulfilled, (state, action) => {
        state.providers = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllProviders.rejected, (state, action: any) => {
        state.providers = [];
        state.loading = false;
        state.errorMsg = true;
      })
      .addCase(getAllProviders.pending, (state, action: any) => {
        state.loading = true;
        state.errorMsg = false;
      });
    builder
      .addCase(new3dPayment.rejected, (state, action) => {
        state.paymentStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(new3dPayment.pending, (state) => {
        state.paymentStatus = "loading";
      })
      .addCase(new3dPayment.fulfilled, (state) => {
        state.paymentStatus = "succeeded";
      });
  },
});

export default paymentLinkSlice.reducer;
