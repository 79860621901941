import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLinkPayment, postLinkPayment } from "helpers/api/inner_urls";

export const getOnePaymentLinkRequest = createAsyncThunk(
  "paymentRequests/getOnePaymentLinkRequest",
  async (uuid: string) => {
    const { data } = await getLinkPayment(uuid);
    return data;
  }
);

export const postLinkPaymentRequest = createAsyncThunk(
  "paymentRequests/postLinkPaymentRequest",
  async (paylod: { uuid: string; body: PosPazarTypes.LinkPaymentPayload }) => {
    const { uuid, body } = paylod;
    const { data } = await postLinkPayment(uuid, body);
    window.document.write(data);
  }
);
