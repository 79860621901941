import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCities,
  getAllCoreProviders,
  getAllCountries,
  getAllTownships,
  sendMerchantApplication,
} from "./coreThunk";

type StateType = {
  countries: Array<PosPazarTypes.Country>;
  cities: Array<PosPazarTypes.City>;
  townships: Array<PosPazarTypes.Township>;
  coreProviders: Array<PosPazarTypes.PosProvider>;
  applicationStatus: PosPazarTypes.ApiRequestStatus;
  errorMessages: Array<string>;
};
export const initialState: StateType = {
  countries: [],
  cities: [],
  townships: [],
  coreProviders: [],
  applicationStatus: "idle",
  errorMessages: [],
};

const paymentLinkSlice = createSlice({
  name: "core",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllCountries.fulfilled, (state: StateType, action) => {
      state.countries = action.payload;
    });
    builder.addCase(getAllCities.fulfilled, (state, action) => {
      state.cities = action.payload;
    });
    builder.addCase(getAllTownships.fulfilled, (state, action) => {
      state.townships = action.payload;
    });
    builder.addCase(
      getAllCoreProviders.fulfilled,
      (state: StateType, action) => {
        const providers = action.payload;
        state.coreProviders = providers.sort((a, b) => a.order - b.order);
      }
    );
    builder.addCase(sendMerchantApplication.fulfilled, (state) => {
      state.applicationStatus = "succeeded";
    });
    builder.addCase(
      sendMerchantApplication.rejected,
      (state: StateType, action: any) => {
        let messages: string[] = ["Unknown error"];
        if (Array.isArray(action?.error?.messages)) {
          messages = action?.error?.messages?.map?.(
            (message: any) => message.message
          );
        }
        state.applicationStatus = "failed";
        state.errorMessages = messages;
      }
    );
    builder.addCase(sendMerchantApplication.pending, (state: StateType) => {
      state.applicationStatus = "loading";
      state.errorMessages = [];
    });
  },
});

export default paymentLinkSlice.reducer;
