import { createSlice } from "@reduxjs/toolkit";
import { getTransaction, getTransactions } from "./transactionThunk";

type StateType = {
  transactions: PosPazarTypes.TransactionListItem[];
  nextPageUrl: string | null;
  prevPageUrl: string | null;
  count: number;
  pageNumber: number;
  transaction: PosPazarTypes.TransactionDetail | null;
  detailModalShow: boolean;
  status: PosPazarTypes.ApiRequestStatus;
  error: string | null;
};

const initialState: StateType = {
  transactions: [],
  nextPageUrl: null,
  prevPageUrl: null,
  count: 0,
  pageNumber: 1,
  transaction: null,
  detailModalShow: false,
  status: "idle",
  error: null,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    closeDetailModal: (state: StateType) => {
      state.detailModalShow = false;
      state.transaction = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getTransactions.pending, (state: StateType) => {
      state.status = "loading";
    });
    builder.addCase(getTransactions.fulfilled, (state: StateType, action) => {
      const { count, next, previous, results } = action.payload;
      state.transactions = results;
      state.nextPageUrl = next;
      state.prevPageUrl = previous;
      state.count = count;
      let previousPageNumber = 0;
      try {
        const previousPageUrl = new URL(previous ?? "");
        const previousPage = previousPageUrl.searchParams.get("page");
        if (previousPage) {
          previousPageNumber = Number(previousPage);
        } else {
          previousPageNumber = 1;
        }
      } catch {
        previousPageNumber = 0;
      }
      state.pageNumber = previousPageNumber + 1;
      state.status = "succeeded";
    });
    builder.addCase(getTransaction.fulfilled, (state: StateType, action) => {
      state.transaction = action.payload;
      state.status = "succeeded";
      state.detailModalShow = true;
    });
    builder.addCase(getTransaction.rejected, (state: StateType, action) => {
      state.error = action.error.message ?? null;
      state.status = "failed";
      state.detailModalShow = false;
    });
    builder.addCase(getTransaction.pending, (state: StateType, action) => {
      state.status = "loading";
      state.detailModalShow = false;
    });
  },
});

export const { closeDetailModal } = transactionSlice.actions;
export default transactionSlice.reducer;
